import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-stop-alert',
  templateUrl: './stop-alert.component.html',
  styleUrls: ['./stop-alert.component.css'],
})
export class StopAlertComponent implements OnInit {
  description: string = '';
  status: String = '';
  constructor(
    public dialogRef: MatDialogRef<StopAlertComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.description = data;
  }

  ngOnInit() {
    console.log(this.description);
  }
  closeDialog(): void {
    this.status = 'cancel';
    this.dialogRef.close();
  }
  endForAll() {
    this.status = 'endForAll';
    this.dialogRef.close();
  }
  Leave() {
    this.status = 'Leave';
    this.dialogRef.close();
  }
}
