<div class="form-container">
  <div
    class="d-flex"
    style="
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    "
  >
    <h6 style="display: contents">Enter Your Name</h6>

    <div
      style="
        height: 25px;
        width: 25px;
        background-color: transparent;
        border: none;
        cursor: pointer;
      "
      (click)="onClose()"
    >
      <ion-icon name="close-outline" style="font-size: 25px"></ion-icon>
    </div>
  </div>

  <div class="form-group">
    <input
      class="form-control"
      type="text"
      name="user_name"
      maxlength="60"
      autocomplete="off"
      [(ngModel)]="userName"
      placeholder="Name"
      (keyup.enter)="onStartVideoCall()"
    />
  </div>

  <div
    style="margin: 10px 0px 10px 0px; display: flex; justify-content: flex-end"
  >
    <button
      [disabled]="!userName || userName.length < 3"
      type="submit"
      class="btn background-primary text-light"
      (click)="onStartVideoCall()"
      style="cursor: pointer"
    >
      Start Video Call
    </button>
  </div>
</div>
