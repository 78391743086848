import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-video-call-form',
  templateUrl: './video-call-form.component.html',
  styleUrls: ['./video-call-form.component.css'],
})
export class VideoCallFormComponent implements OnInit {
  userName: string = '';
  isCancelled: boolean = false;

  constructor(private dialogRef: MatDialogRef<VideoCallFormComponent>) {}

  ngOnInit(): void {}

  onClose() {
    this.isCancelled = true;
    this.dialogRef.close();
  }

  onStartVideoCall() {
    this.isCancelled = false;
    this.dialogRef.close();
  }
}
