<div
  class="container-fluid bg-img text-color-secondary"
  style="margin-top: 90px"
>
  <div class="container">
    <div class="row gx-5">
      <div class="col-lg-4 col-md-6 mb-lg-n5">
        <div class="h-100 d-flex align-items-center">
          <div
            class="d-flex flex-column align-items-center justify-content-center text-center background-primary border-inner p-4"
          >
            <a href="index.html" class="navbar-brand">
              <h1 class="m-0 text-uppercase text-white">
                <i class="fa fa-birthday-cake fs-1 text-dark me-3"></i>Frank
                Bakery
              </h1>
            </a>
            <p class="mt-3">
              Lorem diam sit erat dolor elitr et, diam lorem justo labore amet
              clita labore stet eos magna sit. Elitr dolor eirmod duo tempor
              lorem, elitr clita ipsum sea.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-6">
        <div class="row gx-5">
          <div class="col-lg-4 col-md-12 pt-5 mb-5">
            <h4 class="text-color-primary text-uppercase mb-4">Get In Touch</h4>
            <div class="d-flex mb-2">
              <i class="bi bi-geo-alt text-color-primary me-2"></i>
              <p class="mb-0">123 Street, New York, USA</p>
            </div>
            <div class="d-flex mb-2">
              <i class="bi bi-envelope-open text-color-primary me-2"></i>
              <p class="mb-0">info@example.com</p>
            </div>
            <div class="d-flex mb-2">
              <i class="bi bi-telephone text-color-primary me-2"></i>
              <p class="mb-0">+012 345 67890</p>
            </div>
            <div class="d-flex mt-4">
              <a
                class="btn btn-lg btn-primary btn-lg-square border-inner rounded-0 me-2"
                href="#"
                ><i class="fab fa-twitter fw-normal"></i
              ></a>
              <a
                class="btn btn-lg btn-primary btn-lg-square border-inner rounded-0 me-2"
                href="#"
                ><i class="fab fa-facebook-f fw-normal"></i
              ></a>
              <a
                class="btn btn-lg btn-primary btn-lg-square border-inner rounded-0 me-2"
                href="#"
                ><i class="fab fa-linkedin-in fw-normal"></i
              ></a>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
            <h4 class="text-color-primary text-uppercase mb-4">Quick Links</h4>
            <div class="d-flex flex-column justify-content-start">
              <a class="text-color-secondary mb-2" href="#"
                ><i class="bi bi-arrow-right text-color-primary me-2"></i
                >Home</a
              >
              <a class="text-color-secondary mb-2" href="#"
                ><i class="bi bi-arrow-right text-color-primary me-2"></i>About
                Us</a
              >
              <a class="text-color-secondary mb-2" href="#"
                ><i class="bi bi-arrow-right text-color-primary me-2"></i>Our
                Services</a
              >
              <a class="text-color-secondary mb-2" href="#"
                ><i class="bi bi-arrow-right text-color-primary me-2"></i>Meet
                The Team</a
              >
              <a class="text-color-secondary mb-2" href="#"
                ><i class="bi bi-arrow-right text-color-primary me-2"></i>Latest
                Blog</a
              >
              <a class="text-color-secondary" href="#"
                ><i class="bi bi-arrow-right text-color-primary me-2"></i
                >Contact Us</a
              >
            </div>
          </div>
          <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
            <h4 class="text-color-primary text-uppercase mb-4">News letter</h4>
            <p>Amet justo diam dolor rebum lorem sit stet sea justo kasd</p>
            <form action="">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control border-white p-3"
                  placeholder="Your Email"
                />
                <button class="btn btn-primary">Sign Up</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="container-fluid text-color-secondary py-4"
  style="background: #111111"
>
  <div class="container text-center">
    <p class="mb-0">
      &copy; <a class="text-white border-bottom" href="#">Your Site Name</a>.
      All Rights Reserved. Designed by
      <a class="text-white border-bottom" href="https://htmlcodex.com"
        >HTML Codex</a
      >
    </p>
  </div>
</div>

<!-- (click)="onClickShowViaVideoCall()" -->
<div
  class="back-to-top background-primary p-2 d-flex align-items-center justify-content-center rounded-circle kayool-meet"
  style="cursor: pointer; bottom: 20px"
>
  <ion-icon
    name="videocam-outline"
    class="m-1 kayool-meet"
    style="font-size: 27px; color: #fff"
  ></ion-icon>
</div>
