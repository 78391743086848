<div>
  <h4>{{ title }}</h4>
  <p>{{ msg }}</p>
  <div class="d-flex justify-content-end">
    <button
      (click)="onClickClose()"
      type="submit"
      class="btn background-primary text-light"
      style="cursor: pointer"
    >
      Ok
    </button>
  </div>
</div>
