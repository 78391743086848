<!-- /*******************************************************************************
 * Copyright (C) Cynnox, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential Written by Arun Girivasan <arungirivasan@gmail.com>, June 2019
 ******************************************************************************/ -->
<!-- style="display: none" -->
<div
  id="broadcast-home"
  class="h-100 w-100 bg-black"
  [ngStyle]="{ display: startVideoCall ? 'block' : 'none' }"
>
  <!-- [ngClass]="{ 'broadcast-container-mobile': isFullscreen() }" -->
  <div
    id="broadcast-container"
    (mousemove)="mouseoverEvent()"
    (click)="mouseoverEvent()"
    #fullScrBroadcast
  >
    <!-- show error alerts -->
    <app-km-error-alerts></app-km-error-alerts>
    <app-km-self-preview
      [conference]="conference"
      *ngIf="getView() != 'gridparticipantsView' && showSelfPreview"
    ></app-km-self-preview>

    <div class="position-relative h-100 bg-black">
      <div class="d-flex h-100" *ngIf="getView() != 'gridparticipantsView'">
        <app-km-large-video
          *ngIf="getView() == 'mainView'"
          class="flex-grow-1"
          [ringing]="ringing"
          [connectingWithManager]="connectingWithManager"
        ></app-km-large-video>

        <!-- whiteboard -->
        <!-- <app-white-board
          *ngIf="getView() == 'whiteBoard'"
          [conference]="conference"
          [classroom]="channelDetails"
          class="flex-grow-1"
        ></app-white-board> -->

        <!-- participant view -->
        <!-- <app-km-vertical-participant-view
          *ngIf="conference"
          [conference]="conference"
        ></app-km-vertical-participant-view> -->
      </div>

      <!-- waiting for host alert -->
      <div
        *ngIf="isWaitForHost() && wait_for_host_timer"
        style="z-index: 1"
        class="localVideoDiv screensharing-text-container"
      >
        <div
          style="
            margin-top: 22%;
            color: white;
            align-items: center;
            font-size: 16px;
            font-family: Arial, Helvetica, sans-serif;
            justify-content: center;
            display: flex;
          "
          *ngIf="wait_for_host_timer"
        >
          <div>
            <ion-icon
              style="color: white; margin-bottom: -2px"
              name="timer-outline"
            ></ion-icon>
          </div>
          <div>&nbsp;{{ wait_for_host_timer }}</div>
        </div>
        <p class="loading">Waiting for host to rejoin</p>
      </div>
      <!-- grid view of participants -->
      <app-km-grid-participant-view
        [conference]="conference"
        [navigatedView]="navigatedView"
      >
      </app-km-grid-participant-view>

      <!-- top buttons section -->
      <div
        class="topIconsBtnDiv"
        [ngClass]="{
          showTopWindow: changeMousePoint,
          hideTopWindow: !changeMousePoint,
          fullscreen_btn_container: isFullscreen()
        }"
        *ngIf="conference"
      >
        <!-- left section -->
        <div>
          <div style="padding-left: 5px" class="d-flex align-items-center">
            <!-- video quality -->
            <app-km-video-quality-button
              [conference]="conference"
            ></app-km-video-quality-button>

            <!-- <button class="action_buttons">
              <ion-icon
                name="cloud-offline-outline"
                *ngIf="p2pEnabled; else noP2PIcon"
              ></ion-icon>
              <ng-template #noP2PIcon>
                <ion-icon name="cloud-outline"></ion-icon>
              </ng-template>
            </button> -->

            <!-- recording duration -->
            <!-- <div
              *ngIf="liveStreamRecording"
              class="d-flex align-items-center cursor-pointer"
              (click)="onStopRecording()"
              style="margin-left: 5px"
            >
              <div>
                <div class="recording-animation">
                  <div></div>
                </div>
              </div>
              <div class="liveStreamRecordingDuration">
                {{ liveStreamRecordDuration }}
              </div>
            </div> -->
          </div>
        </div>
        <!-- middle section -->
        <!-- meeting duration timer -->
        <app-km-meeting-duration
          *ngIf="isJoined"
          [ngStyle]="{ opacity: isFullscreen() ? '1' : '0' }"
        ></app-km-meeting-duration>

        <!-- <button (click)="addParticipant()">Add</button>
        <button (click)="removeParticipant()">Remove</button> -->

        <!-- right section -->
        <!-- <button
          (click)="onClickFullScreen()"
          matTooltip="Fullscreen"
          class="action_buttons"
          *ngIf="
            getView() == 'gridparticipantsView';
            else dummyFullscreenButton
          "
        >
          <ion-icon
            name="expand"
            *ngIf="!isFullscreen(); else contractSvg"
          ></ion-icon>
          <ng-template #contractSvg>
            <ion-icon name="contract"></ion-icon>
          </ng-template>
        </button>
        <ng-template #dummyFullscreenButton> -->
        <div style="height: 20px; width: 20px"></div>
        <!-- </ng-template> -->
      </div>

      <br />
      <!-- footer buttons section -->
      <div
        class="d-flex flex-column justify-content-center align-items-center footer_buttons_section"
        [ngClass]="{
          hideBottomBtnDiv: !changeMousePoint,
          iconBackgroundDivFullScreen: isFullscreen() && !miniScreen
        }"
        [ngStyle]="{
          visibility: isJoined ? 'visible' : 'hidden',
          transform:
            !showFooterControls &&
            (getView() == 'whiteBoard' || getView() == 'gridparticipantsView')
              ? 'translateY(90%)'
              : ''
        }"
      >
        <div
          *ngIf="
            getView() == 'whiteBoard' || getView() == 'gridparticipantsView'
          "
        >
          <button
            class="togglePViewButton d-flex align-items-center action_buttons"
            [matTooltip]="showFooterControls ? 'Hide toolbar' : 'Show toolbar'"
            (click)="toogleFooterButtonsVisibility()"
          >
            <ion-icon name="chevron-up" *ngIf="!showFooterControls"></ion-icon>
            <ion-icon name="chevron-down" *ngIf="showFooterControls"></ion-icon>
          </button>
        </div>
        <div
          class="meetingActionButtons p-2"
          [ngStyle]="{
            visibility:
              !showFooterControls &&
              (getView() == 'whiteBoard' || getView() == 'gridparticipantsView')
                ? 'hidden'
                : 'visible'
          }"
        >
          <!-- unmute mic button -->
          <button
            (click)="onClickUnmuteMyMic()"
            class="me-2"
            *ngIf="isMicrophoneMuted(); else micOnButton"
          >
            <div
              class="lds-dual-ring"
              *ngIf="micProcessing; else micIcon"
            ></div>
            <ng-template #micIcon>
              <ion-icon name="mic-off-outline"></ion-icon>
            </ng-template>
          </button>

          <!-- mute mic button -->
          <ng-template #micOnButton>
            <button (click)="onClickMuteMyMic()" class="me-2">
              <ion-icon name="mic"></ion-icon>
            </button>
          </ng-template>

          <!-- camera mute/unmute -->
          <button
            (click)="onClickUnmuteCamera()"
            class="me-2"
            *ngIf="isCameraMuted(); else cameraOffIcon"
          >
            <div
              class="lds-dual-ring"
              *ngIf="cameraProcessing; else cameraIcon"
            ></div>
            <ng-template #cameraIcon>
              <ion-icon name="videocam-off-outline"></ion-icon>
            </ng-template>
          </button>

          <ng-template #cameraOffIcon>
            <button class="me-2" (click)="onClickMuteCamera()">
              <ion-icon name="videocam"></ion-icon>
            </button>
          </ng-template>

          <!-- share screen -->
          <button
            (click)="onClickStartShareScreen()"
            class="me-2"
            *ngIf="isBrowserSupported() && !screenSharing"
          >
            <div
              class="lds-dual-ring"
              *ngIf="screenShareProcessing; else shareScreenIcon"
            ></div>
            <ng-template #shareScreenIcon>
              <mat-icon fontSet="material-icons-outlined"
                >screen_share</mat-icon
              >
            </ng-template>
          </button>

          <button
            (click)="onClickStopScreenshare()"
            class="me-2"
            *ngIf="screenSharing"
          >
            <mat-icon>screen_share</mat-icon>
          </button>

          <!-- whiteboard -->
          <!-- <button
            class="me-2"
            *ngIf="isIamHost()"
            (click)="onClickWhiteBoard()"
            [disabled]="screenSharing"
          >
            <ion-icon
              name="easel"
              *ngIf="getView() == 'whiteBoard'; else notWhiteBoardIcon"
            ></ion-icon>
            <ng-template #notWhiteBoardIcon>
              <ion-icon name="easel-outline"></ion-icon>
            </ng-template>
          </button> -->

          <!-- classroom messages -->
          <!-- <button
            class="me-2"
            *ngIf="isFullscreen() && scrWidth > 769"
            (click)="onClickNavigationButton('messages')"
          >
            <ion-icon
              name="chatbubbles"
              *ngIf="navigatedView == 'messages'; else notMessagesIcon"
            ></ion-icon>
            <ng-template #notMessagesIcon>
              <ion-icon name="chatbubbles-outline"></ion-icon>
            </ng-template>
            <div class="badge" *ngIf="channelDetails?.unseenMessages">
              {{ channelDetails?.unseenMessages }}
            </div>
          </button> -->

          <!-- toggle view -->
          <!-- <button (click)="onToggleView()" class="me-2" *ngIf="isFullscreen()">
            <ion-icon
              name="grid-outline"
              *ngIf="getView() != 'gridparticipantsView'; else gridViewIcon"
            ></ion-icon>
            <ng-template #gridViewIcon>
              <ion-icon name="grid"></ion-icon>
            </ng-template>
          </button> -->

          <!-- more option -->
          <mat-menu
            #menu="matMenu"
            yPosition="above"
            style="background-color: #283447; color: #b8c7e0"
          >
            <!-- <li
            mat-menu-item
            (click)="onStartRecording()"
            *ngIf="
              !liveStreamRecording && enterprisDetails?.enterpriseID == 'KAYOOL'
            "
            *ngIf="isIamHost()"
          >
            Start Recording
          </li>
          <li
            mat-menu-item
            (click)="onStopRecording()"
            *ngIf="liveStreamRecording"
          >
            Stop Recording
          </li> -->
            <button mat-menu-item (click)="onClickSettings()">
              <mat-icon>settings</mat-icon>
              <span>Settings</span>
            </button>
            <!-- <button
              mat-menu-item
              (click)="unMuteAllParticipants()"
              *ngIf="isIamHost()"
            >
              <mat-icon>mic</mat-icon>
              <span>Unmute All</span>
            </button>
            <button
              mat-menu-item
              (click)="muteAllParticipants()"
              *ngIf="isIamHost()"
            >
              <mat-icon fontSet="material-icons-outlined">mic_off</mat-icon>
              <span>Mute All</span>
            </button> -->
          </mat-menu>

          <button class="me-2" [mat-menu-trigger-for]="menu">
            <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
          </button>

          <!-- stop meeting -->
          <button (click)="stopBroadcastBtnClick()" class="stopbutton">
            <mat-icon>call_end</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div id="remoteTracksBroadcaster"></div>
  </div>

  <!-- always on top view -->
  <div id="alwaysOnTopupViewContainer" *ngIf="false" class="d-none">
    <div id="alwaysOnTopupView">
      <!-- self view -->
      <div class="alwaysOnTop-user d-flex flex-column align-items-center">
        <div
          class="w-100 d-flex justify-content-between py-1 bg-black text-light"
        >
          <button (click)="onClickOpenMainWindow()">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="arrow-up-right-from-square"
              class="svg-inline--fa fa-arrow-up-right-from-square"
              role="img"
              height="15"
              width="15"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M384 320c-17.67 0-32 14.33-32 32v96H64V160h96c17.67 0 32-14.32 32-32s-14.33-32-32-32L64 96c-35.35 0-64 28.65-64 64V448c0 35.34 28.65 64 64 64h288c35.35 0 64-28.66 64-64v-96C416 334.3 401.7 320 384 320zM502.6 9.367C496.8 3.578 488.8 0 480 0h-160c-17.67 0-31.1 14.32-31.1 31.1c0 17.67 14.32 31.1 31.99 31.1h82.75L178.7 290.7c-12.5 12.5-12.5 32.76 0 45.26C191.2 348.5 211.5 348.5 224 336l224-226.8V192c0 17.67 14.33 31.1 31.1 31.1S512 209.7 512 192V31.1C512 23.16 508.4 15.16 502.6 9.367z"
              ></path>
            </svg>
          </button>
          <p class="m-0">You</p>
          <button (click)="onToggleUserPreview()">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-up"
              class="svg-inline--fa fa-chevron-up"
              role="img"
              height="15"
              width="15"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              *ngIf="showAlwaysOnTopUserView"
            >
              <path
                fill="currentColor"
                d="M416 352c-8.188 0-16.38-3.125-22.62-9.375L224 173.3l-169.4 169.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25C432.4 348.9 424.2 352 416 352z"
              ></path>
            </svg>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-down"
              class="svg-inline--fa fa-chevron-down"
              role="img"
              height="15"
              width="15"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              *ngIf="!showAlwaysOnTopUserView"
            >
              <path
                fill="currentColor"
                d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"
              ></path>
            </svg>
          </button>
        </div>
        <div
          class="d-flex flex-column justify-content-center align-items-center alwaysOnTop-userCameraPreview position-relative"
          [ngClass]="{ 'd-none': !showAlwaysOnTopUserView }"
        >
          <!-- speak red border -->
          <div
            class="speak-identifier"
            *ngIf="getRemoteAudioLevel(myJitsiID) > 0.3"
            style="bottom: 0"
          ></div>
          <!-- network connectivity indication -->
          <div
            class="network-connectiviy-indication"
            [ngStyle]="{
              'background-color': getConnectionQuality({
                jitsiUserID: myJitsiID
              })
            }"
            style="top: 0; bottom: unset"
            *ngIf="getParticipantsWithoutCurrentUser().length > 0"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="signal"
              class="svg-inline--fa fa-signal"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
              height="10"
              width="10"
            >
              <path
                fill="currentColor"
                d="M544 0c-17.67 0-32 14.33-32 31.1V480C512 497.7 526.3 512 544 512s32-14.33 32-31.1V31.1C576 14.33 561.7 0 544 0zM160 288C142.3 288 128 302.3 128 319.1v160C128 497.7 142.3 512 160 512s32-14.33 32-31.1V319.1C192 302.3 177.7 288 160 288zM32 384C14.33 384 0 398.3 0 415.1v64C0 497.7 14.33 512 31.1 512S64 497.7 64 480V415.1C64 398.3 49.67 384 32 384zM416 96c-17.67 0-32 14.33-32 31.1V480C384 497.7 398.3 512 416 512s32-14.33 32-31.1V127.1C448 110.3 433.7 96 416 96zM288 192C270.3 192 256 206.3 256 223.1v256C256 497.7 270.3 512 288 512s32-14.33 32-31.1V223.1C320 206.3 305.7 192 288 192z"
              ></path>
            </svg>
          </div>
          <video
            autoplay="1"
            playsInline
            muted
            [srcObject]="getUserCameraStream()"
            *ngIf="getUserCameraStream(); else userProfilePreview"
            style="transform: scaleX(-1)"
          ></video>
          <ng-template #userProfilePreview>
            <img
              [src]="getUserAvatar()"
              class="rounded-circle"
              width="75"
              height="75"
              style="object-fit: contain"
            />
          </ng-template>
          <!-- <div *ngIf="isIamHost()" class="px-2 host-tag">Host</div> -->
        </div>
        <!-- bottom buttons -->
        <div
          class="alwaysOnTop-broadcastHandlers d-flex flex-row justify-content-between"
          [ngClass]="{ 'd-none': !showAlwaysOnTopUserView }"
        >
          <!-- unmute mic button -->
          <button
            (click)="onClickUnmuteMyMic()"
            class="alwaysOnTopButton mutedBackgroundColor d-flex align-items-center justify-content-center"
            *ngIf="isMicrophoneMuted(); else micOnButtonPopUpView"
          >
            <div
              class="lds-dual-ring"
              *ngIf="micProcessing; else micIconPopUpView"
            ></div>
            <ng-template #micIconPopUpView>
              <svg
                height="24"
                width="24"
                viewBox="0 0 32 32"
                style="fill: #fff; margin-left: -2px"
              >
                <path
                  d="M5.688 4l22.313 22.313-1.688 1.688-5.563-5.563c-1 .625-2.25 1-3.438 1.188v4.375h-2.625v-4.375c-4.375-.625-8-4.375-8-8.938h2.25c0 4 3.375 6.75 7.063 6.75 1.063 0 2.125-.25 3.063-.688l-2.188-2.188c-.25.063-.563.125-.875.125-2.188 0-4-1.813-4-4v-1l-8-8zM20 14.875l-8-7.938v-.25c0-2.188 1.813-4 4-4s4 1.813 4 4v8.188zm5.313-.187a8.824 8.824 0 01-1.188 4.375L22.5 17.375c.375-.813.563-1.688.563-2.688h2.25z"
                ></path>
              </svg>
            </ng-template>
          </button>

          <!-- mute mic button -->
          <ng-template #micOnButtonPopUpView>
            <button
              (click)="onClickMuteMyMic()"
              class="alwaysOnTopButton notMutedBtnBackground"
            >
              <svg
                height="24"
                width="24"
                viewBox="0 0 32 32"
                style="fill: #5e6d7a; margin-left: -2px"
              >
                <path
                  d="M23.063 14.688h2.25c0 4.563-3.625 8.313-8 8.938v4.375h-2.625v-4.375c-4.375-.625-8-4.375-8-8.938h2.25c0 4 3.375 6.75 7.063 6.75s7.063-2.75 7.063-6.75zm-7.063 4c-2.188 0-4-1.813-4-4v-8c0-2.188 1.813-4 4-4s4 1.813 4 4v8c0 2.188-1.813 4-4 4z"
                ></path>
              </svg>
            </button>
          </ng-template>

          <!-- exit button -->
          <button
            (click)="stopBroadcastBtnClick()"
            class="alwaysOnTopButton stopbutton"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style="color: white"
              viewBox="0 0 512 512"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="32"
                d="M368 368L144 144M368 144L144 368"
              />
            </svg>
          </button>
          <!-- camera unmute button -->
          <button
            (click)="onClickUnmuteCamera()"
            class="alwaysOnTopButton mutedBackgroundColor d-flex align-items-center justify-content-center"
            *ngIf="isCameraMuted(); else cameraOffIconPopUpView"
          >
            <div
              class="lds-dual-ring"
              *ngIf="cameraProcessing; else cameraIconPopUpView"
            ></div>
            <ng-template #cameraIconPopUpView>
              <svg
                height="24"
                width="24"
                viewBox="0 0 32 32"
                style="fill: #fff; margin-left: -2px"
              >
                <path
                  d="M4.375 2.688L28 26.313l-1.688 1.688-4.25-4.25c-.188.125-.5.25-.75.25h-16c-.75 0-1.313-.563-1.313-1.313V9.313c0-.75.563-1.313 1.313-1.313h1L2.687 4.375zm23.625 6v14.25L13.062 8h8.25c.75 0 1.375.563 1.375 1.313v4.688z"
                ></path>
              </svg>
            </ng-template>
          </button>

          <!-- camera mute button -->
          <ng-template #cameraOffIconPopUpView>
            <button
              class="alwaysOnTopButton notMutedBtnBackground"
              (click)="onClickMuteCamera()"
            >
              <svg
                height="24"
                width="24"
                viewBox="0 0 32 32"
                style="fill: #5e6d7a; margin-left: -2px"
              >
                <path
                  d="M22.688 14l5.313-5.313v14.625l-5.313-5.313v4.688c0 .75-.625 1.313-1.375 1.313h-16C4.563 24 4 23.437 4 22.687V9.312c0-.75.563-1.313 1.313-1.313h16c.75 0 1.375.563 1.375 1.313V14z"
                ></path>
              </svg>
            </button>
          </ng-template>
        </div>
      </div>
      <!-- other participants section -->
      <div
        class="w-100 d-flex justify-content-between py-1 bg-black text-light"
        *ngIf="getParticipantsWithoutCurrentUser().length > 0"
      >
        <p class="m-0" style="margin-left: 5px !important">
          {{ getParticipantsTitle() }}
        </p>
        <button (click)="onToggleParticipantView()">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="chevron-up"
            class="svg-inline--fa fa-chevron-up"
            role="img"
            height="15"
            width="15"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            *ngIf="showAlwaysOnTopParticipantView"
          >
            <path
              fill="currentColor"
              d="M416 352c-8.188 0-16.38-3.125-22.62-9.375L224 173.3l-169.4 169.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25C432.4 348.9 424.2 352 416 352z"
            ></path>
          </svg>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="chevron-down"
            class="svg-inline--fa fa-chevron-down"
            role="img"
            height="15"
            width="15"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            *ngIf="!showAlwaysOnTopParticipantView"
          >
            <path
              fill="currentColor"
              d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"
            ></path>
          </svg>
        </button>
      </div>
      <div
        [ngClass]="{ 'd-none': !showAlwaysOnTopParticipantView }"
        class="bg-black position-relative popupViewParticipantList"
        [ngStyle]="{ height: popupViewParticipantsHeight + 'px' }"
      >
        <!-- participant list view -->
        <div
          *ngFor="
            let parti of getParticipantsWithoutCurrentUser();
            let i = index
          "
          class="position-relative"
        >
          <div>
            <!-- live tag -->
            <!-- <div class="px-2 live-tag" *ngIf="isIamPresenter(parti)">
                LIVE
              </div> -->
            <!-- speak red border -->
            <div
              class="speak-identifier"
              *ngIf="getRemoteAudioLevel(parti?.jitsiUserID) > 0.3"
            ></div>
            <!-- network connectivity indication -->
            <div
              class="network-connectiviy-indication"
              [ngStyle]="{
                'background-color': getConnectionQuality(parti)
              }"
              *ngIf="!parti?.fakeParticipant"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="signal"
                class="svg-inline--fa fa-signal"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height="10"
                width="10"
              >
                <path
                  fill="currentColor"
                  d="M544 0c-17.67 0-32 14.33-32 31.1V480C512 497.7 526.3 512 544 512s32-14.33 32-31.1V31.1C576 14.33 561.7 0 544 0zM160 288C142.3 288 128 302.3 128 319.1v160C128 497.7 142.3 512 160 512s32-14.33 32-31.1V319.1C192 302.3 177.7 288 160 288zM32 384C14.33 384 0 398.3 0 415.1v64C0 497.7 14.33 512 31.1 512S64 497.7 64 480V415.1C64 398.3 49.67 384 32 384zM416 96c-17.67 0-32 14.33-32 31.1V480C384 497.7 398.3 512 416 512s32-14.33 32-31.1V127.1C448 110.3 433.7 96 416 96zM288 192C270.3 192 256 206.3 256 223.1v256C256 497.7 270.3 512 288 512s32-14.33 32-31.1V223.1C320 206.3 305.7 192 288 192z"
                ></path>
              </svg>
            </div>
            <!-- mic-on/off indicator -->
            <div
              class="participant-mic-indicator"
              [ngStyle]="{
                cursor: isIamHost() ? 'pointer' : 'default'
              }"
              (click)="onClickPopupParticipantMic(parti?.jitsiUserID)"
              *ngIf="!parti?.fakeParticipant"
            >
              <!--  iamBroadcaster || -->
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="microphone-slash"
                class="svg-inline--fa fa-microphone-slash"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
                height="18"
                width="18"
                *ngIf="
                  isRemoteAudioTrackMuted(parti?.jitsiUserID);
                  else mic_on_indicator
                "
              >
                <path
                  fill="currentColor"
                  d="M383.1 464l-39.1-.0001v-33.77c20.6-2.824 39.98-9.402 57.69-18.72l-43.26-33.91c-14.66 4.65-30.28 7.179-46.68 6.144C245.7 379.6 191.1 317.1 191.1 250.9V247.2L143.1 209.5l.0001 38.61c0 89.65 63.97 169.6 151.1 181.7v34.15l-40 .0001c-17.67 0-31.1 14.33-31.1 31.1C223.1 504.8 231.2 512 239.1 512h159.1c8.838 0 15.1-7.164 15.1-15.1C415.1 478.3 401.7 464 383.1 464zM630.8 469.1l-159.3-124.9c15.37-25.94 24.53-55.91 24.53-88.21V216c0-13.25-10.75-24-23.1-24c-13.25 0-24 10.75-24 24l-.0001 39.1c0 21.12-5.559 40.77-14.77 58.24l-25.72-20.16c5.234-11.68 8.493-24.42 8.493-38.08l-.001-155.1c0-52.57-40.52-98.41-93.07-99.97c-54.37-1.617-98.93 41.95-98.93 95.95l0 54.25L38.81 5.111C34.41 1.673 29.19 0 24.03 0C16.91 0 9.839 3.158 5.12 9.189c-8.187 10.44-6.37 25.53 4.068 33.7l591.1 463.1c10.5 8.203 25.57 6.328 33.69-4.078C643.1 492.4 641.2 477.3 630.8 469.1z"
                ></path>
              </svg>
              <ng-template #mic_on_indicator>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="microphone"
                  class="svg-inline--fa fa-microphone"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  height="18"
                  width="18"
                >
                  <path
                    fill="currentColor"
                    d="M192 352c53.03 0 96-42.97 96-96v-160c0-53.03-42.97-96-96-96s-96 42.97-96 96v160C96 309 138.1 352 192 352zM344 192C330.7 192 320 202.7 320 215.1V256c0 73.33-61.97 132.4-136.3 127.7c-66.08-4.169-119.7-66.59-119.7-132.8L64 215.1C64 202.7 53.25 192 40 192S16 202.7 16 215.1v32.15c0 89.66 63.97 169.6 152 181.7V464H128c-18.19 0-32.84 15.18-31.96 33.57C96.43 505.8 103.8 512 112 512h160c8.222 0 15.57-6.216 15.96-14.43C288.8 479.2 274.2 464 256 464h-40v-33.77C301.7 418.5 368 344.9 368 256V215.1C368 202.7 357.3 192 344 192z"
                  ></path>
                </svg>
              </ng-template>
            </div>
            <div
              class="d-flex flex-column justify-content-center align-items-center alwaysOnTop-userCameraPreview"
              style="border-bottom: 1px solid #5e6d7a"
            >
              <video
                autoplay="1"
                playsInline
                muted
                [srcObject]="
                  getRemoteVideoStreamOfParticipants(parti?.jitsiUserID)
                "
                *ngIf="
                  getRemoteVideoStreamOfParticipants(parti?.jitsiUserID);
                  else userProfilePreview
                "
              ></video>
              <ng-template #userProfilePreview>
                <img
                  [src]="getParticipantAvatar(parti?.avatar)"
                  class="rounded-circle"
                  width="75"
                  height="75"
                  style="object-fit: contain"
                />
              </ng-template>
            </div>
            <!-- <div
                class="host-tag-p-view-container d-flex justify-content-center"
                *ngIf="isliveClassHost(parti)"
              >
                <div class="px-2 host-tag-p-view">Host</div>
              </div> -->
            <div class="alwaysOnTopParticipantName">
              {{ getParticipantName(parti) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <audio
    #ringer
    src="assets/sounds/phone_ring.mp3"
    (ended)="onRingerEnded($event)"
  ></audio>
</div>
