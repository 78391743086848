import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Stomp from '@stomp/stompjs';
import { Subject } from 'rxjs';
import * as SockJS from 'sockjs-client';
import { environment } from 'src/environments/environment';
import { ActiveCall, ActiveCallsWebsocket } from '../model/Calls';
import { UserRes } from '../model/UserRes';
import { v4 as uuidv4 } from 'uuid';

const websocketUrl = environment.websocketUrl;
const apiURL = environment.api;

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private stompClient = null;
  webSocketConnected = false;
  websocketConnecting = false;
  userID: string = '';
  customer: boolean = true;
  private customerCallObserver = new Subject<ActiveCallsWebsocket>();
  private adminCallsObserver = new Subject<ActiveCallsWebsocket>();

  constructor(private httpClient: HttpClient) {}

  getCustomerCallObservable() {
    return this.customerCallObserver.asObservable();
  }

  getAdminCallsObservable() {
    return this.adminCallsObserver.asObservable();
  }

  initWebsocketConnection(customer: boolean) {
    this.customer = customer;
    this.getUserID()
      .then(() => {
        this.connect();
      })
      .catch(() => {
        console.error('error');
      });
  }

  getUserID() {
    return new Promise((resolve, reject) => {
      this.httpClient.get(`${apiURL}/v1/users/session/new`).subscribe({
        next: (res: UserRes) => {
          console.log(res);
          this.userID = res.id;
        },
        error: (err) => {
          console.error(err);
          reject(err);
        },
        complete: () => {
          resolve(this.userID);
        },
      });
    });
  }

  getAllActiveCalls() {
    return new Promise((resolve, reject) => {
      this.httpClient.get(`${apiURL}/v1/call`).subscribe({
        next: (res: ActiveCall[]) => {
          // console.log(res);
          resolve(res);
        },
        error: (err) => {
          console.error(err);
          reject(err);
        },
        complete: () => {},
      });
    });
  }

  addNewCall(name: string) {
    return new Promise((resolve, reject) => {
      let req = {
        uuid: uuidv4(),
        name: name,
      };

      let header = new HttpHeaders().set('user', this.userID);
      this.httpClient
        .post(`${apiURL}/v1/call`, req, { headers: header })
        .subscribe({
          next: (res: ActiveCall) => {
            resolve(res);
          },
          error: (err) => {
            reject(err);
          },
          complete: () => {
            // resolve(this.userID);
          },
        });
    });
  }

  stopMyCall() {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders().set('user', this.userID);
      this.httpClient
        .delete(`${apiURL}/v1/call`, { headers: header })
        .subscribe({
          next: (res) => {
            resolve(res);
          },
          error: (err) => {
            reject(err);
          },
          complete: () => {
            // resolve(this.userID);
          },
        });
    });
  }

  stopUserCall(callID: string) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders().set('user', this.userID);
      this.httpClient
        .delete(`${apiURL}/v2/call/${callID}`, { headers: header })
        .subscribe({
          next: (res) => {
            resolve(res);
          },
          error: (err) => {
            reject(err);
          },
          complete: () => {
            // resolve(this.userID);
          },
        });
    });
  }

  markAsAnsweredAPI(callID: string) {
    return new Promise((resolve, reject) => {
      this.httpClient.get(`${apiURL}/v1/call/${callID}/accept`).subscribe({
        next: (res: ActiveCall[]) => {
          // console.log(res);
          resolve(res);
        },
        error: (err) => {
          console.error(err);
          reject(err);
        },
        complete: () => {},
      });
    });
  }

  getStatusOfACall(callID: string) {
    return new Promise((resolve, reject) => {
      this.httpClient.get(`${apiURL}/v1/call/${callID}/status`).subscribe({
        next: (res: ActiveCall[]) => {
          // console.log(res);
          resolve(res);
        },
        error: (err) => {
          console.error(err);
          reject(err);
        },
        complete: () => {},
      });
    });
  }

  connect() {
    if (this.webSocketConnected || this.websocketConnecting) return;
    this.websocketConnecting = true;
    console.log('connecting user websocket..', this.customer);
    const socket = new SockJS(websocketUrl);
    this.stompClient = Stomp.Stomp.over(socket);
    const that = this;
    /**
     * disable debugging
     */
    // this.stompClient.reconnect_delay = 5000;
    that.stompClient.debug = () => {};
    //
    this.stompClient.connect(
      {},
      (frame) => {
        console.log('websocket connected');
        // that.triggerWebsocketConnectionObservable(true);
        //channel msg
        that.webSocketConnected = true;
        this.websocketConnecting = false;

        if (this.customer) {
          that.stompClient.subscribe(
            '/topic/call_' + this.userID,
            function (msg) {
              let data = JSON.parse(msg.body);
              // console.log(data);

              that.customerCallObserver.next(data);
            },
            {
              user: this.userID,
            }
          );
        } else {
          //shop owner
          that.stompClient.subscribe('/topic/calls', function (msg) {
            let data = JSON.parse(msg.body);
            // console.log(data);
            that.adminCallsObserver.next(data);
          });
        }
      },
      (message) => {
        let time = new Date();
        console.error(time, 'error');
      },
      (message) => {
        let time = new Date();
        console.error(time, 'websocket closed. will reconnect in 3sec');
        that.webSocketConnected = false;
        that.websocketConnecting = false;
        that.stompClient = null;
        setTimeout(() => {
          that.connect();
        }, 3000);
      }
    );
  }

  disconnect() {
    if (this.stompClient != null) {
      this.stompClient.disconnect();
    }
    // console.log('Disconnected User!');
  }
}
