/*******************************************************************************
 * Copyright (C) Cynnox, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential Written by Arun Girivasan <arun@kollegenet.com>, June 2019
 ******************************************************************************/
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { VideoBroadcastService } from '../../service/video-broadcast.service';

@Component({
  selector: 'broadcast-mini',
  templateUrl: './broadcast-mini.component.html',
  styleUrls: ['./broadcast-mini.component.scss'],
})
export class BroadcastMiniComponent implements OnInit, OnDestroy {
  pos1 = 0;
  pos2 = 0;
  pos3 = 0;
  pos4 = 0;
  grabbed: boolean = false;

  constructor(
    private broadcastService: VideoBroadcastService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    // this.hideShowListener = this.broadcastService.getBroadcastMiniShowHideLIstener().subscribe((show: boolean) => {
    //   this.pos1 = 0; this.pos2 = 0; this.pos3 = 0; this.pos4 = 0;
    //   let broadcastMini = document.getElementById('broadcast-mini');
    //   if (show) {
    //     this.renderer.addClass(broadcastMini, "show-broadcastMini");
    //   } else {
    //     this.renderer.removeClass(broadcastMini, "show-broadcastMini");
    //   }
    //   this.renderer.setStyle(broadcastMini, "top", "");
    //   this.renderer.setStyle(broadcastMini, "left", "");
    // });
    // this.broadCastWebsocketLister = this.userService.getBroadCastWebsocketListener().subscribe((res: ChannelBroadcastWebSocketRes) => {
    //   if (res.delete) {
    //     if (this.broadcastService.isBroadcastWatching() && this.broadcastService.isBroadcastMiniActive()) {
    //       if (res.channelID === this.broadcastService.getBroadcastingChannel().id) {
    //         this.broadcastService.stopWatchingBroadcast();
    //       }
    //     }
    //     if (
    //       this.broadcastService.isBroadcasting() &&
    //       this.broadcastService.isBroadcastMiniActive()
    //     ) {
    //       if (
    //         res.channelID === this.broadcastService.getBroadcastingChannel().id
    //       ) {
    //         this.broadcastService.stopActiveBroadcast();
    //       }
    //     }
    //   }
    // });
  }

  ngOnDestroy() {
    // this.hideShowListener.unsubscribe();
    // this.broadCastWebsocketLister.unsubscribe();
  }

  onMouseDown(e: any) {
    e.preventDefault();
    // get the mouse cursor position at startup:
    this.pos3 = e.clientX;
    this.pos4 = e.clientY;
    this.grabbed = true;
  }

  onMouseUp() {
    this.grabbed = false;
  }

  async onMouseMove(e: any, elem: any) {
    if (this.grabbed) {
      e.preventDefault();
      // calculate the new cursor position:
      this.pos1 = this.pos3 - e.clientX;
      this.pos2 = this.pos4 - e.clientY;
      this.pos3 = e.clientX;
      this.pos4 = e.clientY;
      // set the element's new position:
      // let maxLeft = window.innerWidth - 330;
      // let maxTop = window.innerHeight - 200;
      let maxLeft = window.innerWidth - elem.offsetWidth;
      let maxTop = window.innerHeight - elem.offsetHeight;
      let top = elem.offsetTop - this.pos2;
      let left = elem.offsetLeft - this.pos1;
      if (top >= 0 && top <= maxTop && left >= 0 && left <= maxLeft) {
        this.renderer.setStyle(elem, 'top', top + 'px');
        this.renderer.setStyle(elem, 'left', left + 'px');
      }
    }
  }

  async onTouchMove(e: TouchEvent, elem: any) {
    // e.preventDefault();
    // calculate the new cursor position:
    var touch = e.targetTouches[0];
    this.pos1 = this.pos3 - touch.clientX;
    this.pos2 = this.pos4 - touch.clientY;
    this.pos3 = touch.clientX;
    this.pos4 = touch.clientY;

    // let maxLeft = window.innerWidth - 270;
    // let maxTop = window.innerHeight - 150;
    let maxLeft = window.innerWidth - elem.offsetWidth;
    let maxTop = window.innerHeight - elem.offsetHeight;
    let top = elem.offsetTop - this.pos2;
    let left = elem.offsetLeft - this.pos1;
    if (top >= 0 && top <= maxTop && left >= 0 && left <= maxLeft) {
      this.renderer.setStyle(elem, 'top', top + 'px');
      this.renderer.setStyle(elem, 'left', left + 'px');
    }
  }

  onTouchStart(e: TouchEvent) {
    // get the mouse cursor position at startup:
    var touch = e.targetTouches[0];
    this.pos3 = touch.clientX;
    this.pos4 = touch.clientY;
  }
}
