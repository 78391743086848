<!-- Products Start -->
<div class="container-fluid about py-5">
  <div class="container">
    <div
      class="section-title position-relative text-center mx-auto mb-5 pb-3"
      style="max-width: 600px"
    >
      <h2 class="text-color-primary font-secondary">Menu & Pricing</h2>
      <h1 class="display-4 text-uppercase">Explore Our Cakes</h1>
    </div>
    <div class="tab-class text-center">
      <ul
        class="nav nav-pills d-inline-flex justify-content-center bg-dark text-uppercase border-inner p-4 mb-5"
      >
        <li class="nav-item">
          <a
            class="nav-link text-white active"
            data-bs-toggle="pill"
            href="#tab-1"
            >Birthday</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link text-white" data-bs-toggle="pill" href="#tab-2"
            >Wedding</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link text-white" data-bs-toggle="pill" href="#tab-3"
            >Custom</a
          >
        </li>
      </ul>
      <div class="tab-content">
        <div id="tab-1" class="tab-pane fade show p-0 active">
          <div class="row g-3">
            <div class="col-lg-6" *ngFor="let i of [1, 2, 3, 4, 5, 6]">
              <app-product-item name="Birthday Cake"></app-product-item>
            </div>
          </div>
        </div>
        <div id="tab-2" class="tab-pane fade show p-0">
          <div class="row g-3">
            <div class="col-lg-6" *ngFor="let i of [1, 2, 3, 4, 5, 6]">
              <app-product-item name="Wedding Cake"></app-product-item>
            </div>
          </div>
        </div>
        <div id="tab-3" class="tab-pane fade show p-0">
          <div class="row g-3">
            <div class="col-lg-6" *ngFor="let i of [1, 2, 3, 4, 5, 6]">
              <app-product-item name="Custom Cake"></app-product-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Products End -->
