/*******************************************************************************
 * Copyright (C) Cynnox, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential Written by Arun Girivasan <arun@kollegenet.com>, June 2019
 ******************************************************************************/

export const MediaDevice = {
  AUDIO: "audio",
  VIDEO: "video",
  DESKTOP: "desktop"
}
export const UserCommand = {
  USER_INFO: "user_info",
  UNMUTE_ALL_MIC: "unmute_all_mic",
  MUTE_ALL_MIC: "mute_all_mic",
  UNMUTE_ONE_MIC: "unmute_one_mic",
  MUTE_ONE_MIC: "mute_one_mic",
  PRESENTER: "presenter",
  BROADCAST_MULTI_MODE: "broadcast_multi_mode",
  SCREEN_MODE: "screen_mode",
  WHITE_BORAD_TOOLBAR: "white_board_toolbar",
}

export const ScreenModes = {
  VIDEO: "video",
  WHITE_BOARD: "whiteboard"
}