<div class="container-fluid bg-offer my-5 py-5">
  <div class="container py-5">
    <div class="row gx-5 justify-content-center">
      <div class="col-lg-7 text-center">
        <div
          class="section-title position-relative text-center mx-auto mb-4 pb-3"
          style="max-width: 600px"
        >
          <h2 class="text-color-primary font-secondary">Special Kombo Pack</h2>
          <h1 class="display-4 text-uppercase text-white">
            Super Crispy Cakes
          </h1>
        </div>
        <p class="text-white mb-4">
          Eirmod sed tempor lorem ut dolores sit kasd ipsum. Dolor ea et dolore
          et at sea ea at dolor justo ipsum duo rebum sea. Eos vero eos vero ea
          et dolore eirmod et. Dolores diam duo lorem. Elitr ut dolores magna
          sit. Sea dolore sed et.
        </p>
        <a class="btn btn-primary border-inner py-3 px-5 me-3">Shop Now</a>
        <!-- (click)="onClickShowViaVideoCall()" -->
        <a class="btn btn-dark border-inner py-3 px-5 kayool-meet"
          ><div
            class="d-inline-flex align-items-center justify-content-center cursor-pointer kayool-meet"
          >
            <ion-icon
              name="videocam-outline"
              class="me-3"
              style="font-size: 25px"
            ></ion-icon>
            <div class="text-start kayool-meet">Connect to Shop</div>
          </div></a
        >
      </div>
    </div>
  </div>
</div>
