import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ActiveCall, ActiveCallsWebsocket } from 'src/app/model/Calls';
import { KayoolMeetService } from 'src/app/modules/kayool-meet/service/kayool-meet.service';
import { VideoBroadcastService } from 'src/app/modules/kayool-meet/service/video-broadcast.service';
import { WebsocketService } from 'src/app/services/websocket.service';
import { VideoCallFormComponent } from '../video-call-form/video-call-form.component';

@Component({
  selector: 'app-offer-section',
  templateUrl: './offer-section.component.html',
  styleUrls: ['./offer-section.component.css'],
})
export class OfferSectionComponent implements OnInit, OnDestroy {
  activeCall: ActiveCall = null;

  private sessionStopSubscription: Subscription;
  private customerCallsSubscription: Subscription;

  constructor(
    private videoBroadcastService: VideoBroadcastService,
    private dialog: MatDialog,
    private websocketService: WebsocketService,
    private kayoolMeetService: KayoolMeetService
  ) {}

  ngOnInit(): void {
    this.customerCallsSubscription = this.websocketService
      .getCustomerCallObservable()
      .subscribe((res: ActiveCallsWebsocket) => {
        if (res.delete) {
          if (this.activeCall && this.activeCall.id == res.call.id) {
            console.log('call stopped by shop');
            //stop call
            this.videoBroadcastService.stopActiveBroadcast();
            this.activeCall = null;
          }
        }
      });

    this.sessionStopSubscription = this.kayoolMeetService
      .getSessionStopObservable()
      .subscribe(() => {
        if (this.activeCall) {
          console.log('self stop');

          this.activeCall = null;
          this.websocketService
            .stopMyCall()
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.websocketService.disconnect();
    if (this.sessionStopSubscription) {
      this.sessionStopSubscription.unsubscribe();
    }
    if (this.customerCallsSubscription) {
      this.customerCallsSubscription.unsubscribe();
    }
  }

  onClickShowViaVideoCall() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(VideoCallFormComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      let userName = dialogRef.componentInstance.userName;
      let isCancelled = dialogRef.componentInstance.isCancelled;

      if (!isCancelled) {
        this.websocketService
          .addNewCall(userName)
          .then((res: ActiveCall) => {
            this.activeCall = res;
            this.videoBroadcastService.broadcast({
              avatarUrl: '',
              classroom: null,
              firstName: userName,
              lastName: '',
              isUser: true,
              kollegenetUserID: 0,
              rejoinAsBroadcaster: false,
              room: res.id,
              twoSideVideo: true,
              userID: 0,
              ring: true,
              startWithVideoMuted: true,
            });
          })
          .catch((err) => {});
      }
    });
  }
}
