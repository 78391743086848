import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActiveCall, ActiveCallsWebsocket } from 'src/app/model/Calls';
import { VideoBroadcastService } from 'src/app/modules/kayool-meet/service/video-broadcast.service';
import { WebsocketService } from 'src/app/services/websocket.service';
import * as moment from 'moment';
import { KayoolMeetService } from 'src/app/modules/kayool-meet/service/kayool-meet.service';
import { CallStatus } from 'src/app/modules/kayool-meet/model/CallStatus';

interface IncomingCalls {
  name: string;
  image: string;
  time: string;
  room: string;
  userID: string;
  acceptedTime: Date;
}

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.css'],
})
export class AdminPageComponent implements OnInit, OnDestroy {
  incomingCalls: IncomingCalls[] = [];
  activeCall: IncomingCalls = null;

  private adminCallsSubscription: Subscription;
  private sessionStopSubscription: Subscription;

  constructor(
    private videoBroadcastService: VideoBroadcastService,
    private websocketService: WebsocketService,
    private kayoolMeetService: KayoolMeetService
  ) {
    // this.incomingCalls.push({
    //   name: 'Mark',
    //   image: '../../../assets/template2/images/faces/face4.jpg',
    //   time: '1 Second ago',
    //   room: '',
    // });
    // this.incomingCalls.push({
    //   name: 'Cregh',
    //   image: '../../../assets/template2/images/faces/face2.jpg',
    //   time: '15 Seconds ago',
    //   room: '',
    // });
    // this.incomingCalls.push({
    //   name: 'Johnson',
    //   image: '../../../assets/template2/images/faces/face3.jpg',
    //   time: '18 Seconds ago',
    //   room: '',
    // });
  }

  ngOnInit(): void {
    // this.websocketService.initWebsocketConnection(false);

    this.adminCallsSubscription = this.websocketService
      .getAdminCallsObservable()
      .subscribe((res: ActiveCallsWebsocket) => {
        console.log(res);
        if (res.delete) {
          if (this.activeCall && this.activeCall.room == res.call.id) {
            console.log('call stopped by customer');
            //stop call
            this.videoBroadcastService.stopActiveBroadcast();
            this.activeCall = null;
          }
          let index = this.incomingCalls.findIndex(
            (c) => c.room == res.call.id
          );
          if (index > -1) {
            this.incomingCalls.splice(index, 1);
          }
        } else {
          this.incomingCalls.push({
            name: res.call.name,
            image: '../../../assets/template2/images/faces/face4.jpg',
            time: moment
              .utc(res.call.time)
              .local()
              .format('DD MMM yyyy, hh:mm A'),
            room: res.call.id,
            userID: res.call.userID,
            acceptedTime: res.call.acceptedTime,
          });
        }
      });

    this.sessionStopSubscription = this.kayoolMeetService
      .getSessionStopObservable()
      .subscribe(() => {
        if (this.activeCall) {
          console.log('self stop');

          this.websocketService
            .stopUserCall(this.activeCall.room)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.error(err);
            });
          this.activeCall = null;
        }
      });

    this.getAllActiveCalls();
  }

  ngOnDestroy(): void {
    this.websocketService.disconnect();
    if (this.adminCallsSubscription) {
      this.adminCallsSubscription.unsubscribe();
    }
    if (this.sessionStopSubscription) {
      this.sessionStopSubscription.unsubscribe();
    }
  }

  getAllActiveCalls() {
    this.websocketService
      .getAllActiveCalls()
      .then((res: ActiveCall[]) => {
        console.log(res);
        for (let index = 0; index < res.length; index++) {
          const call = res[index];

          this.incomingCalls.push({
            name: call.name,
            image: '../../../assets/template2/images/faces/face4.jpg',
            time: moment.utc(call.time).local().format('DD MMM yyyy, hh:mm A'),
            room: call.id,
            userID: call.userID,
            acceptedTime: call.acceptedTime,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  onClickCall(call: IncomingCalls) {
    this.websocketService
      .getStatusOfACall(call.room)
      .then((res: CallStatus) => {
        if (res.callExist) {
          if (res.answered) {
            alert('Sorry! This call is already answered by another executive.');
            call.acceptedTime = new Date();
          } else {
            this.answerCall(call);
          }
        }
      })
      .catch((err) => {
        setTimeout(() => {
          this.onClickCall(call);
        }, 1000);
      });
  }

  answerCall(call: IncomingCalls) {
    this.websocketService
      .markAsAnsweredAPI(call.room)
      .then((res) => {
        this.activeCall = call;
        this.videoBroadcastService.broadcast({
          avatarUrl: '',
          classroom: null,
          firstName: 'Manager',
          lastName: '',
          isUser: true,
          kollegenetUserID: 0,
          rejoinAsBroadcaster: false,
          room: call.room,
          twoSideVideo: true,
          userID: 0,
          ring: false,
          startWithVideoMuted: false,
        });
      })
      .catch((err) => {
        setTimeout(() => {
          this.answerCall(call);
        }, 1000);
      });
  }

  getFirstLetter(name: string) {
    return name.charAt(0).toUpperCase();
  }

  getColorBasedOnText(text: string) {
    const colors = {
      a: { bg: 'red', text: 'white' },
      b: { bg: 'orange', text: 'white' },
      c: { bg: 'yellow', text: 'black' },
      d: { bg: 'green', text: 'white' },
      e: { bg: 'blue', text: 'white' },
      f: { bg: 'indigo', text: 'white' },
      g: { bg: 'violet', text: 'white' },
      h: { bg: 'purple', text: 'white' },
      i: { bg: 'pink', text: 'black' },
      j: { bg: 'brown', text: 'white' },
      k: { bg: 'gray', text: 'black' },
      l: { bg: 'black', text: 'white' },
      m: { bg: 'white', text: 'black' },
      n: { bg: 'gold', text: 'black' },
      o: { bg: 'silver', text: 'black' },
      p: { bg: 'maroon', text: 'white' },
      q: { bg: 'navy', text: 'white' },
      r: { bg: 'olive', text: 'white' },
      s: { bg: 'teal', text: 'white' },
      t: { bg: 'lime', text: 'black' },
      u: { bg: 'coral', text: 'black' },
      v: { bg: 'plum', text: 'white' },
      w: { bg: 'salmon', text: 'black' },
      x: { bg: 'khaki', text: 'black' },
      y: { bg: 'beige', text: 'black' },
      z: { bg: 'aqua', text: 'black' },
    };
    return colors[text.charAt(0).toLowerCase()];
  }
}
