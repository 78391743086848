/*******************************************************************************
 * Copyright (C) Cynnox, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential Written by Arun Girivasan <arun@kollegenet.com>, June 2019
 ******************************************************************************/
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/pages/alert-dialog/alert-dialog.component';
import { WebsocketService } from 'src/app/services/websocket.service';
import { isIamHost } from '../../lib-jitsi-meet/functions';
import { CallStatus } from '../../model/CallStatus';
import { CONFERENCE_OPTIONS } from '../../model/CONFERENCE_OPTIONS';
import { CONNECTION_OPTIONS } from '../../model/CONNECTION_OPTIONS';
import { CONNECTION_REQ } from '../../model/CONNECTION_REQ';
import { UserActions } from '../../model/CustomActionTypes';
import {
  IJitsiConference,
  IParticipant,
  IStats,
  MediaType,
} from '../../model/jitsi';
import { JITSI_MEET_JS_INIT_OPTIONS } from '../../model/JITSI_MEET_JS_INIT_OPTIONS';
import { BroadcastConfig } from '../../model/model';
import {
  MediaDevice,
  ScreenModes,
  UserCommand,
} from '../../resources/resources';
import { KayoolMeetService } from '../../service/kayool-meet.service';
import { VideoBroadcastService } from '../../service/video-broadcast.service';
import { AudioMixerEffect } from '../AudioMixerEffect';
import { KmeetDeviceSettingsComponent } from '../kmeet-device-settings/kmeet-device-settings.component';
import JitsiStreamPresenterEffect from '../presenter-effect/VideoStreamPresenterEffect';
import { stopTrack } from '../RTCUtils';
import { StopAlertComponent } from '../stop-alert/stop-alert.component';
import { SCREEN_VIEWS } from './SCREEN_VIEWS';

declare var JitsiMeetJS: any;

@Component({
  selector: 'broadcaster',
  templateUrl: './broadcaster.component.html',
  styleUrls: ['./broadcaster.component.scss'],
})
export class BroadcasterComponent implements OnInit, OnDestroy {
  startVideoCall: boolean = false;
  connection: any;
  conference: IJitsiConference;
  isJoined = false;

  screenSharing = false;
  connectingWithManager = false;
  roomName: string = '';
  avatarUrl: string = '';
  kollegenetUserID: number = 0;
  firstName: string = '';
  lastName: string = '';
  scrWidth: any;
  miniScreen: boolean = false;
  changeMousePoint: boolean = false;
  myJitsiID: string = '';
  exitSession: boolean = true;
  showSelfPreview: boolean = false;
  cameraVideoTrack: any = null;
  desktopVideoTrack: any = null;
  audioTrack: any = null;
  desktopAudioTrack: any = null;
  audioEffect: AudioMixerEffect = null;
  // desktopVideoStream: any = null;
  localPresenterVideoTrack: any = null;
  // presenterEffect: JitsiStreamPresenterEffect = null;
  cameraProcessing: boolean = false;
  micProcessing: boolean = false;
  screenShareProcessing: boolean = false;
  disconnected: boolean = false;
  // liveClassHosts: liveClassHosts[] = null;
  mainVideoProps: any = {};
  wait_for_host_timer: string = '';
  INTERVAL_TIMEOUT = 3;
  rejoinAsBroadcaster: boolean = false;
  //this variable true means live class is started by this user
  iamBroadcaster: boolean = true;
  showAlwaysOnTopUserView: boolean = true;
  showAlwaysOnTopParticipantView: boolean = true;
  alwaysOnTopRender: any = null;
  screenSharingRender: any = null;
  maxNoOfParticipantsPopupView: number = 0;
  hostChangeLoader: any = {};
  previous_view: SCREEN_VIEWS = SCREEN_VIEWS.MAIN_VIEW;

  controllsTimeout: any = null;

  liveStreamRecording: boolean = false;
  liveStreamRecordDuration: string = '';
  navigatedView: string = '';
  notAttendingQuizCount: number = 0;
  showFooterControls: boolean = true;
  popupViewParticipantsHeight: number = 0;
  p2pEnabled: boolean = true;
  startWithVideoMuted: boolean = false;

  @ViewChild('ringer') ringer: ElementRef = null;
  ringing: boolean = false;

  private broadcastListener: Subscription;
  private stopBroadcastActionListener: Subscription;
  private broadcastMiniShowHideLIstener: Subscription;
  private broadCastWebsocketLister: Subscription;
  private timerListener: Subscription;
  private exitBroadcastActionListener: Subscription;
  private websocketConnectionListener: Subscription;
  private recordSubscription: Subscription;
  private recordDurationSubscription: Subscription;
  private assessmentSubscription: Subscription;
  private classroomSubscription: Subscription;
  private newPresenterSubscription: Subscription;
  private myMicrophoneStatusSubscription: Subscription;
  private viewSubscription: Subscription;
  private determinePopupHeightSubscription: Subscription;
  private fullscreenSubscription: Subscription;
  private participantUpdatesSubscription: Subscription;

  @Output() onBroadcast = new EventEmitter<boolean>();

  constructor(
    private renderer: Renderer2,
    public broadcastService: VideoBroadcastService,
    private dialog: MatDialog,
    private kayoolMeetService: KayoolMeetService,
    private websocketService: WebsocketService
  ) {
    JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
  }

  ngOnDestroy(): void {
    if (this.broadcastListener) {
      this.broadcastListener.unsubscribe();
    }
    if (this.timerListener) {
      this.timerListener.unsubscribe();
    }

    if (this.stopBroadcastActionListener) {
      this.stopBroadcastActionListener.unsubscribe();
    }

    if (this.broadcastMiniShowHideLIstener) {
      this.broadcastMiniShowHideLIstener.unsubscribe();
    }
    if (this.broadCastWebsocketLister) {
      this.broadCastWebsocketLister.unsubscribe();
    }
    if (this.exitBroadcastActionListener)
      this.exitBroadcastActionListener.unsubscribe();

    if (this.websocketConnectionListener)
      this.websocketConnectionListener.unsubscribe();

    if (this.recordDurationSubscription)
      this.recordDurationSubscription.unsubscribe();

    if (this.recordSubscription) this.recordSubscription.unsubscribe();

    if (this.assessmentSubscription) {
      this.assessmentSubscription.unsubscribe();
    }
    if (this.classroomSubscription) {
      this.classroomSubscription.unsubscribe();
    }
    if (this.newPresenterSubscription) {
      this.newPresenterSubscription.unsubscribe();
    }
    if (this.myMicrophoneStatusSubscription) {
      this.myMicrophoneStatusSubscription.unsubscribe();
    }
    if (this.viewSubscription) {
      this.viewSubscription.unsubscribe();
    }
    if (this.determinePopupHeightSubscription) {
      this.determinePopupHeightSubscription.unsubscribe();
    }
    if (this.fullscreenSubscription) {
      this.fullscreenSubscription.unsubscribe();
    }
    if (this.participantUpdatesSubscription) {
      this.participantUpdatesSubscription.unsubscribe();
    }

    this.kayoolMeetService.resetAll();
  }

  ngOnInit(): void {
    this.exitSession = true;
    /*
     * For Electron
     * Logic to open a desktop picker put on the window global for
     * lib-jitsi-meet to detect and invoke
     */
    let that = this;
    window['JitsiMeetScreenObtainer'] = {
      openDesktopPicker(options, onSourceChoose) {
        that.showDesktopPicker(options, onSourceChoose);
      },
    };

    // wait for host timer listener
    this.timerListener = this.broadcastService
      .gettimerObservable()
      .subscribe((res) => {
        if (res) {
          this.settimer(2);
        }
      });

    //broadcast start listener
    this.broadcastListener = this.broadcastService
      .getBroadcastistener()
      .subscribe((config: BroadcastConfig) => {
        JitsiMeetJS.init(JITSI_MEET_JS_INIT_OPTIONS);
        this.conference = null;
        this.myJitsiID = '';
        this.showSelfPreview = false;
        this.connectingWithManager = false;
        this.isJoined = false;
        this.disconnected = false;
        this.startWithVideoMuted = false;

        this.iamBroadcaster = true;
        this.roomName = config.room;
        this.firstName = config.firstName;
        this.lastName = config.lastName;
        this.avatarUrl = config.avatarUrl;
        this.kollegenetUserID = config.kollegenetUserID;
        this.rejoinAsBroadcaster = config.rejoinAsBroadcaster;
        this.startVideoCall = true;
        this.startWithVideoMuted = config.startWithVideoMuted;
        if (config.ring) {
          this.startRing();
        }
        this.startBroadcast();
        this.onBroadcast.emit(true);
      });

    // /**get broadcast listener for user */
    // this.userService
    //   .getBroadCastWebsocketListener()
    //   .subscribe((res: ChannelBroadcastWebSocketRes) => {
    //     console.log("ChannelBroadcastWebSocketRes");
    //     console.log(res);
    //     console.log(this.channelDetails);
    //     console.log(this.broadcastService.broadcastingChannel);
    //     console.log("ChannelBroadcastWebSocketRes");
    //     if (this.channelDetails.id === res.channelID) {
    //       this.liveClassHosts = res.liveClassHosts;
    //     }
    //   });

    //stop broadcast started by this user - listener
    this.stopBroadcastActionListener = this.broadcastService
      .getStopBroadcastActionListener()
      .subscribe((stop) => {
        console.log('getStopBroadcastActionListener');

        // if (
        //   this.isIamHost() &&
        //   this.channelDetails?.liveClassHosts?.length == 1
        // ) {
        //   // if user is host and only one host is present
        //   // call stop session
        //   this.exitSession = false;
        // } else {
        //   this.exitSession = true;
        // }
        this.stopBroadcast(false);
      });

    //exit from broadcast joined - listener
    this.exitBroadcastActionListener = this.broadcastService
      .getStopBroadcastWatchActionListener()
      .subscribe((stop) => {
        console.log('getStopBroadcastWatchActionListener');
        this.exitSession = true;
        this.stopBroadcast(true);
      });

    this.broadcastMiniShowHideLIstener = this.broadcastService
      .getBroadcastMiniShowHideLIstener()
      .subscribe((res) => {
        this.miniScreen = res;
      });
    // websocket connection observer
    // this.websocketConnectionListener = this.userService
    //   .getWebsocketConnectionObservable()
    //   .subscribe((connected) => {
    //     if (this.isJoined) {
    //       if (this.iamBroadcaster) {
    //         console.log('websocketConnectionListener');

    //         this.onBroadcastStarted(this.myJitsiID);
    //       } else {
    //         this.joinParticipantsEntryReport();
    //       }
    //     }
    //   });
    this.getScreenSize();

    //for live stream recording
    // this.recordSubscription = this.liveStreamRecordingService
    //   .getRecordObservable()
    //   .subscribe((recording: boolean) => {
    //     this.liveStreamRecording = recording;
    //     if (!recording) this.liveStreamRecordDuration = '';
    //   });

    // this.recordDurationSubscription = this.liveStreamRecordingService
    //   .getRecordDurationObservable()
    //   .subscribe((duration: number) => {
    //     this.liveStreamRecordDuration = this.readableDuration(duration);
    //   });

    // this.liveStreamRecordingService.resetRecording();

    // this.assessmentSubscription = this.userService
    //   .getParticipantQuizWebsocketListener()
    //   .subscribe((res: ParticipantQuestionWebSocketRes) => {
    //     if (
    //       this.channelDetails &&
    //       res.questionaire.channelID == this.channelDetails.id
    //     ) {

    //     }
    //   });

    // this.classroomSubscription = this.userService
    //   .getClassroomObservable()
    //   .subscribe((res: ChannelFollowingUsersOnlyWebSocket) => {
    //     if (
    //       this.channelDetails &&
    //       this.channelDetails?.id === res.channel?.id
    //     ) {
    //       if (this.navigatedView != 'messages') {
    //         this.channelDetails.unseenMessages = res?.channel.unseenMessages;
    //       }
    //     }
    //   });

    this.myMicrophoneStatusSubscription = this.kayoolMeetService
      .getMyMicrophoneStatusObservable()
      .subscribe((micOn: boolean) => {
        if (micOn) {
          this.onClickUnmuteMyMic();
        } else {
          this.onClickMuteMyMic();
        }
      });

    this.newPresenterSubscription = this.kayoolMeetService
      .getNewPresenterObservable()
      .subscribe(this.selectPresenter.bind(this));

    this.determinePopupHeightSubscription = this.kayoolMeetService
      .getDeterminePopviewHeightObservable()
      .subscribe(() => {
        this.determinePopupViewHeight();
      });

    this.fullscreenSubscription = this.broadcastService
      .getFullScreenBroadcastListener()
      .subscribe((fullscreen) => {
        this.navigatedView = '';
      });

    this.participantUpdatesSubscription = this.kayoolMeetService
      .getParticipantUpdateObservable()
      .subscribe(() => {
        // stop ringer
        this.stopRinger();
      });
  }

  readableDuration(duration: number): string {
    var hr = Math.floor(duration / 3600);
    var min = Math.floor((duration % 3600) / 60);
    var sec = Math.floor((duration % 3600) % 60);

    var hDisplay = hr > 0 ? (hr < 10 ? '0' : '') + hr + ':' : '';
    var mDisplay =
      min > 0 ? (min < 10 ? '0' : '') + min + ':' : min < 1 ? '00:' : '';
    var sDisplay = sec > 0 ? (sec < 10 ? '0' : '') + sec : sec < 1 ? '00' : '';
    return hDisplay + mDisplay + sDisplay;
  }

  /**
   * for open desktop sharing dialog in electron
   * @param {Object} options - Desktop sharing settings.
   * @param {Function} onSourceChoose - The callback to invoke when
   * a DesktopCapturerSource has been chosen.
   */
  showDesktopPicker(options: any = {}, onSourceChoose) {
    const { desktopSharingSources } = options;
    // if (this.electronService.isElectron) {
    //   const dialogRef = this.dialog.open(DesktopPickerComponent, {
    //     panelClass: 'custom-dialog-container',
    //     data: {
    //       desktopSharingSources,
    //       onSourceChoose,
    //       screenSharingRender: this.screenSharingRender,
    //     },
    //     disableClose: true,
    //   });

    //   dialogRef.afterClosed().subscribe((result) => {
    //     // console.log(result);
    //     // console.log("desktop picker dialog closed")
    //   });
    // } else {
    //   console.error('Platform is not electron');
    // }
  }

  /**get Screen Size */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
  }

  /**
   * start broadcasting
   */
  startBroadcast() {
    this.broadcastService.broadcastFullScreen(true);
    // CONNECTION_OPTIONS.serviceUrl += "?room=" + this.roomName;
    // console.log(CONNECTION_OPTIONS);

    this.connection = new JitsiMeetJS.JitsiConnection(
      null,
      null,
      CONNECTION_OPTIONS
    );

    this.connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      this.onConnectionSuccess.bind(this)
    );
    this.connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      this.onConnectionFailed.bind(this)
    );
    this.connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
      this.disconnect.bind(this)
    );

    this.connection.connect(CONNECTION_REQ);
  }

  getLocalAudioTrack() {
    return new Promise((resolve, reject) => {
      this.micProcessing = true;
      JitsiMeetJS.createLocalTracks({ devices: [MediaDevice.AUDIO] })
        .then((tracks) => {
          if (this.disconnected) {
            console.error('removing video track - server disconnected');
            stopTrack(tracks[0]);
          }
          this.kayoolMeetService.showMicrophonePermissionError(false);
          this.audioTrack = tracks[0];
          // if (
          //   this.enterprisDetails &&
          //   this.enterprisDetails.conferenceSettings
          // ) {
          //   let startWithMic =
          //     !this.enterprisDetails.conferenceSettings
          //       .broadcasterStartMicMuted;
          //   if (!startWithMic || !this.iamBroadcaster) {
          //     console.log('Start with mic muted');
          //     this.audioTrack.mute();
          //   }
          // }
          this.conference.addTrack(this.audioTrack);
          this.micProcessing = false;
          resolve(tracks);
        })
        .catch((error) => {
          this.kayoolMeetService.showMicrophonePermissionError(true);
          console.error(error);
          this.micProcessing = false;
          reject(error);
        });
    });
  }

  getLocalVideoTrack() {
    console.log('getLocalVideoTrack called');

    return new Promise((resolve, reject) => {
      this.cameraProcessing = true;
      JitsiMeetJS.createLocalTracks({ devices: [MediaDevice.VIDEO] })
        .then((tracks) => {
          if (this.disconnected) {
            stopTrack(tracks[0]);
          }
          this.kayoolMeetService.showCameraPermissionError(false);
          this.cameraVideoTrack = tracks[0];
          // if (this.startWithVideoMuted) {
          //   console.log('Start with video muted');
          //   this.cameraVideoTrack.mute();
          // }
          // if (
          //   this.enterprisDetails &&
          //   this.enterprisDetails.conferenceSettings
          // ) {
          //   let startWithCamera =
          //     !this.enterprisDetails.conferenceSettings
          //       .broadcasterStartCameraMuted;
          //   if (!startWithCamera || !this.iamBroadcaster) {
          //     console.log('Start with video muted');
          //     this.cameraVideoTrack.mute();
          //   }
          // }
          this.conference.addTrack(this.cameraVideoTrack);

          this.cameraProcessing = false;
          resolve(tracks);
        })
        .catch((error) => {
          this.kayoolMeetService.showCameraPermissionError(true);
          console.error(error);
          this.cameraProcessing = false;
          reject(error);
        });
    });
  }

  /**
   * this function is executed when the conference is joined
   */
  //event - CONFERENCE_JOINED
  onConferenceJoined() {
    console.log('on conference joined');

    // this.broadcasterId = this.broadcastService.getBroadcastingChannel().broadcastID
    if (this.disconnected) return;
    this.myJitsiID = this.conference.myUserId();
    this.kayoolMeetService.myJitsiID = this.myJitsiID;
    this.kayoolMeetService.joinedTime = new Date();

    this.sendUserInfoCommand();
    this.showSelfPreview = true;

    if (!this.kayoolMeetService.largeVideoUser) {
      this.kayoolMeetService.setLargeVideoUser(this.myJitsiID);
    }
    this.kayoolMeetService.remoteTracks[this.myJitsiID] = [];
    this.isJoined = true;

    this.getLocalAudioTrack();
    this.getLocalVideoTrack();

    /**
     * set the desired resolution to send to JVB or the peer (180, 360, 720, 1080, etc).
     */
    if (this.iamBroadcaster) {
      this.sendBroadcastMode();
      const participant = this.myJitsiID;
      this.kayoolMeetService.sendPresenter(participant);
      this.onBroadcastStarted(this.myJitsiID);
    } else {
      /**
       * send video quality
       */
      this.joinParticipantsEntryReport();
    }
  }

  /**
   * send user defined command user_info
   * set user fname and lname,
   * avatar,kollegenet userID,Jitsi ID,pin or not
   */
  sendUserInfoCommand() {
    console.log('send user info command');

    let user: IParticipant = {
      firstName: this.firstName,
      lastName: this.lastName,
      avatar: this.avatarUrl ? this.avatarUrl : '',
      kollegenetUser: this.kollegenetUserID,
      jitsiUserID: this.myJitsiID,
      token: '',
      fakeParticipant: undefined,
      joinedTime: new Date().toString(),
    };

    let jitsiUserID = this.myJitsiID;
    /**
     * add user to participants
     */
    this.kayoolMeetService.participants.unshift(user);

    this.kayoolMeetService.triggerParticipantUpdate();
    this.conference.sendCommand(UserCommand.USER_INFO, {
      value: jitsiUserID,
      attributes: user,
    });
    this.kayoolMeetService.decideToAutoChangeView_GridAndLargeVideo(
      jitsiUserID,
      UserActions.JOIN
    );
    this.determinePopupViewHeight();
  }

  // addParticipant() {
  //   let user: IParticipant = {
  //     firstName: this.firstName,
  //     lastName: this.lastName,
  //     avatar: this.avatarUrl ? this.avatarUrl : "",
  //     kollegenetUser: this.kollegenetUserID,
  //     jitsiUserID: this.myJitsiID,
  //     token: this.authToken,
  //     fakeParticipant: undefined
  //   };
  //   this.kayoolMeetService.participants.unshift(user);
  //   this.kayoolMeetService.triggerParticipantUpdate();
  // }

  // removeParticipant() {
  //   this.kayoolMeetService.participants.splice(0, 1);
  //   this.kayoolMeetService.triggerParticipantUpdate();
  // }

  /**
   * send broadcast mode to other participants
   */
  sendBroadcastMode() {
    this.conference.sendCommand(UserCommand.BROADCAST_MULTI_MODE, {
      value: true,
      attributes: {
        twoSideMode: true,
      },
    });
  }

  /**
   *on remote user left from conference
   * @param id
   */
  // event - JitsiMeetJS.events.conference.USER_LEFT
  onUserLeftFromConference(jitsiUserID: string) {
    // if left user is presenter, remove presenter rights from him and assign to broadcaster
    if (
      this.kayoolMeetService.presenter == jitsiUserID &&
      this.iamBroadcaster
    ) {
      this.kayoolMeetService.sendPresenter(this.myJitsiID);
    }
    this.kayoolMeetService.removeUserFromParticipants(jitsiUserID);
    const tracks = this.kayoolMeetService.remoteTracks[jitsiUserID];

    if (tracks) {
      for (let i = 0; i < tracks.length; i++) {
        let participant = jitsiUserID;
        const elemID = participant + '-' + tracks[i].getType();
        let videoAudioElement = document.getElementById(elemID);
        if (videoAudioElement) {
          tracks[i].detach(videoAudioElement);
        }
      }
    }

    let remoteTracks = document.getElementById('remoteTracksBroadcaster');
    if (remoteTracks) {
      let remoteParticipant = document.getElementById(jitsiUserID + '-remote');
      if (remoteParticipant) {
        this.renderer.removeChild(remoteTracks, remoteParticipant);
      }
    }
  }

  /**
   * this function is called when connection is established successfully
   */
  onConnectionSuccess() {
    console.log('onConnectionSuccess called');

    this.conference = this.connection.initJitsiConference(
      this.roomName,
      CONFERENCE_OPTIONS
    );
    this.kayoolMeetService.conference = this.conference;

    // if (this.electronService.isElectron) {
    //   console.log('Platform is electron, adding always on top module');
    //   const { setupAlwaysOnTopRender, setupScreenSharingRender } = (
    //     window as any
    //   ).kayoolNodeApi;

    //   this.alwaysOnTopRender = setupAlwaysOnTopRender(this, JitsiMeetJS);
    //   this.screenSharingRender = setupScreenSharingRender(this, JitsiMeetJS);
    //   this.maxNoOfParticipantsPopupView =
    //     this.getNoofParticipantsCanBeVisibleInPopup();
    //   console.log(
    //     'We can show total ' +
    //       this.maxNoOfParticipantsPopupView +
    //       ' in popup view'
    //   );
    // } else {
    //   // console.log("Platform is not electron, cant add always on top module")
    // }

    this.conference.on(
      JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED,
      this._onTrackMuteChanged.bind(this)
    );
    this.conference.on(
      JitsiMeetJS.events.conference.TRACK_ADDED,
      this._onRemoteTrackAdded.bind(this)
    );
    this.conference.on(
      JitsiMeetJS.events.conference.TRACK_REMOVED,
      this._onRemoteTrackRemoved.bind(this)
    );
    this.conference.on(
      JitsiMeetJS.events.conference.CONFERENCE_JOINED,
      this.onConferenceJoined.bind(this)
    );
    this.conference.on(
      JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED,
      this._onLocalStatsUpdated.bind(this)
    );
    this.conference.on(
      JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED,
      this._onRemoteStatsUpdated.bind(this)
    );
    this.conference.on(
      JitsiMeetJS.events.conference.USER_JOINED,
      this.onNewUserJoinedOnConference.bind(this)
    );
    this.conference.on(
      JitsiMeetJS.events.conference.USER_LEFT,
      this.onUserLeftFromConference.bind(this)
    );
    this.conference.on(
      JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
      this.onTrackAudioLevelChanged.bind(this)
    );
    this.conference.on(
      JitsiMeetJS.events.conference.P2P_STATUS,
      this.onP2Pstatus.bind(this)
    );

    // this.conference.on(JitsiMeetJS.events.conference.TALK_WHILE_MUTED, (data) => {
    //   console.log("talk while muted");
    //   console.log(data);

    // });

    // this.conference.on(JitsiMeetJS.events.conference.NOISY_MIC, (data) => {
    //   console.log("noisy mic");
    //   console.log(data);

    // });

    // this.conference.on(
    //   JitsiMeetJS.events.conference.CONFERENCE_CREATED_TIMESTAMP,
    //   (conferenceTimestamp) => {
    //     console.log("conferenceTimestamp ", conferenceTimestamp);

    //   });

    this.listenToUserCommands();
    this.conference.join();
  }

  onP2Pstatus(jitsiConference, p2p) {
    this.p2pEnabled = p2p;
    console.log('P2P ', p2p);
  }

  // event - JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED
  _onTrackMuteChanged(track) {
    const participant = track.getParticipantId();
    if (participant == this.myJitsiID && track.getType() == MediaType.VIDEO) {
      // this.cameraMuted = track.isMuted();
    }
  }

  // event - TRACK_AUDIO_LEVEL_CHANGED
  onTrackAudioLevelChanged(jitsiUserID: string, audioLevel: number) {
    //// console.log(`${jitsiUserID} - ${audioLevel}`);
    this.kayoolMeetService.remoteAudioLevels[jitsiUserID] = audioLevel;
    if (audioLevel > 0.2) {
      // switching speaking user to large video screen
      if (
        this.kayoolMeetService.largeVideoUser != jitsiUserID &&
        jitsiUserID != this.myJitsiID
      ) {
        this.kayoolMeetService.setLargeVideoUser(jitsiUserID);
      }
    }
    this.kayoolMeetService.triggerAudioLevel({
      audioLevel: audioLevel,
      jitsiUserID: jitsiUserID,
    });
    this.changeParticipantsPosition(jitsiUserID, audioLevel);
  }

  changeParticipantsPosition(jitsiUserID: string, audioLevel: number) {
    if (audioLevel >= 0.2) {
      //change order
      let startIndex = 1;
      if (this.screenSharing) {
        startIndex = 2;
      }
      let maxUsersOnView = 5;
      if (this.getView() == SCREEN_VIEWS.GRID_PARTICIPANT_VIEW) {
        maxUsersOnView = 20;
      }

      let userIndex = this.kayoolMeetService.participants.findIndex(
        (p) => p.jitsiUserID == jitsiUserID
      );
      let users = this.kayoolMeetService.participants.filter(
        (p) =>
          this.kayoolMeetService.getOriginalJitsiUserID(p.jitsiUserID) ==
          jitsiUserID
      );
      if (userIndex > -1) {
        if (userIndex > maxUsersOnView - 1) {
          for (let i = 0; i < users.length; i++) {
            console.log(userIndex + i, startIndex + i);

            this.array_move(
              this.kayoolMeetService.participants,
              userIndex + i,
              startIndex + i
            );
          }
          console.log('user moved');
        } else {
          console.log('user on view not moving');
        }
      }
    }
  }

  // event - JitsiMeetJS.events.conference.USER_JOINED
  onNewUserJoinedOnConference(jitsiUserID) {
    console.log(
      'new user joined conference : ' + jitsiUserID + ' me ' + this.myJitsiID
    );
    this.kayoolMeetService.remoteTracks[jitsiUserID] = [];
    /**
     * push to participants array
     */
    this.kayoolMeetService.participants.push({
      jitsiUserID: jitsiUserID,
      avatar: '',
      firstName: '',
      kollegenetUser: 0,
      lastName: '',
      token: '',
      joinedTime: '',
    });

    this.kayoolMeetService.decideToChangeLargeVideoUserWhileJoinOrLeftParticipant(
      jitsiUserID,
      false
    );
    this.kayoolMeetService.decideToAutoChangeView_GridAndLargeVideo(
      jitsiUserID,
      UserActions.JOIN
    );
    this.kayoolMeetService.triggerParticipantUpdate();

    this.determinePopupViewHeight();
  }

  getConnectionQuality(participant: any) {
    if (participant.jitsiUserID == this.myJitsiID) {
      if (this.kayoolMeetService.myStats) {
        if (this.kayoolMeetService.myStats.connectionQuality > 60) {
          return 'green';
        } else if (
          this.kayoolMeetService.myStats.connectionQuality > 30 &&
          this.kayoolMeetService.myStats.connectionQuality <= 60
        ) {
          return 'orange';
        }
      }
      return 'red';
    } else {
      let stats = this.kayoolMeetService.remoteStats.get(
        participant.jitsiUserID
      );
      if (stats) {
        if (stats.connectionQuality > 60) {
          return 'green';
        } else if (
          stats.connectionQuality > 30 &&
          stats.connectionQuality <= 60
        ) {
          return 'orange';
        }
      }
      return 'red';
    }
  }

  getConnectionDetials(participant: any) {
    let quality = 'NaN';
    let str = '';
    if (participant.jitsiUserID == this.myJitsiID) {
      if (this.kayoolMeetService.myStats) {
        if (this.kayoolMeetService.myStats.connectionQuality > 60) {
          quality = 'Good';
        } else if (
          this.kayoolMeetService.myStats.connectionQuality > 30 &&
          this.kayoolMeetService.myStats.connectionQuality <= 60
        ) {
          quality = 'Nonoptimal';
        } else {
          quality = 'Poor';
        }
        str =
          'Connection : ' +
          quality +
          '\n Bitrate : ↓' +
          this.kayoolMeetService.myStats.bitrate.download +
          ' Kbps ↑' +
          this.kayoolMeetService.myStats.bitrate.upload +
          ' Kbps \n PacketLoss : ↓' +
          this.kayoolMeetService.myStats.packetLoss.download +
          ' Kbps ↑' +
          this.kayoolMeetService.myStats.packetLoss.upload +
          ' Kbps';
      }
      return str;
    } else {
      let stats = this.kayoolMeetService.remoteStats.get(
        participant.jitsiUserID
      );
      if (stats) {
        if (stats.connectionQuality > 60) {
          quality = 'Good';
        } else if (
          stats.connectionQuality > 30 &&
          stats.connectionQuality <= 60
        ) {
          quality = 'Nonoptimal';
        } else {
          quality = 'Poor';
        }
        str =
          'Connection: ' +
          quality +
          '\n Bitrate: ↓' +
          stats.bitrate.download +
          ' Kbps ↑' +
          stats.bitrate.upload +
          ' Kbps \n PacketLoss: ↓' +
          stats.packetLoss.download +
          ' Kbps ↑' +
          stats.packetLoss.upload +
          ' Kbps ';
      }
      return str;
    }
  }

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  onUnmuteOneMic(data) {
    let participant = data.value;
    //// console.log(participant, this.myJitsiID)
    if (participant === this.myJitsiID) {
      this.onClickUnmuteMyMic();
    }
  }

  onMuteOneMic(data) {
    let participant = data.value;
    if (participant === this.myJitsiID) {
      this.onClickMuteMyMic();
    }
  }

  onCommandPresenter(data) {
    let participant = data.value;
    // console.log("UserCommand.PRESENTER listener ");
    const { from } = data.attributes;

    if (from) {
      if (from != this.myJitsiID) {
        // remove my pin participant command if other user made pin participant command
        // console.log("removing presenter command");
        this.conference.removeCommand(UserCommand.PRESENTER);
      }
    }

    if (this.kayoolMeetService.presenter != participant) {
      // console.log(data);
      // console.log("select participant calling from UserCommand.PRESENTER listener");
      // console.log(this.participants);

      this.selectPresenter(participant);
    }
  }

  onCommandScreenMode(data) {
    console.log('onCommandScreenMode()', data.value);
    const { from } = data.attributes;
    if (from) {
      if (from != this.myJitsiID) {
        // remove my pin participant command if other user made pin participant command
        // console.log("removing SCREEN_MODE command");
        this.conference.removeCommand(UserCommand.SCREEN_MODE);
      }
    }
    if (data.value == 'whiteboard') {
      this.kayoolMeetService.view = SCREEN_VIEWS.WHITEBOARD;
      this.broadcastService.broadcastFullScreen(true);
    } else if (data.value == 'video') {
      this.kayoolMeetService.decideToAutoChangeView_GridAndLargeVideo(
        this.kayoolMeetService.largeVideoUser,
        UserActions.NONE
      );
    }
  }

  onCommandWhiteboardToolbar(data) {
    const { from } = data.attributes;
    if (from) {
      if (from != this.myJitsiID) {
        // remove my pin participant command if other user made pin participant command
        // console.log("removing WHITE_BORAD_TOOLBAR command");
        this.conference.removeCommand(UserCommand.WHITE_BORAD_TOOLBAR);
      }
    }
    if (data.attributes.jitsiUserID == this.myJitsiID) {
      // if (Boolean(data.value)) {
      //   this.userService.setToolbarAccessToggle(true);
      // } else {
      //   this.userService.setToolbarAccessToggle(false);
      // }
    }
  }

  /**
   * listen to user defined commands
   */
  listenToUserCommands() {
    this.conference.addCommandListener(
      UserCommand.USER_INFO,
      this.onUserInfoCommand.bind(this)
    );
    this.conference.addCommandListener(
      UserCommand.UNMUTE_ONE_MIC,
      this.onUnmuteOneMic.bind(this)
    );
    this.conference.addCommandListener(
      UserCommand.MUTE_ONE_MIC,
      this.onMuteOneMic.bind(this)
    );
    this.conference.addCommandListener(
      UserCommand.PRESENTER,
      this.onCommandPresenter.bind(this)
    );
    this.conference.addCommandListener(
      UserCommand.UNMUTE_ALL_MIC,
      this.onClickUnmuteMyMic.bind(this)
    );
    this.conference.addCommandListener(
      UserCommand.MUTE_ALL_MIC,
      this.onClickMuteMyMic.bind(this)
    );
    this.conference.addCommandListener(
      UserCommand.SCREEN_MODE,
      this.onCommandScreenMode.bind(this)
    );
    this.conference.addCommandListener(
      UserCommand.WHITE_BORAD_TOOLBAR,
      this.onCommandWhiteboardToolbar.bind(this)
    );
  }

  onUserInfoCommand(data) {
    this.kayoolMeetService.onUserInfoCommand(data);
  }

  /**
   * This function is called when the connection fail.
   */
  onConnectionFailed(e) {
    console.error('Connection failed');
    console.error(e);

    // if (this.isIamHost() && this.channelDetails?.liveClassHosts?.length == 1) {
    //   // if user is host and only one host is present
    //   // call stop session
    //   this.exitSession = false;
    // } else {
    //   this.exitSession = true;
    // }
    this.stopBroadcast(!this.iamBroadcaster);
    this.disconnect();
    console.error('Connection Failed!');
    // this.componentHelper.showToast(
    //   'Connection Failed! Please check your internet connection and try again',
    //   5000
    // );
  }

  showExitOrStopAlert(msg: string) {
    const dialogRef = this.dialog.open(StopAlertComponent, {
      panelClass: 'custom-dialog-container',
      data: msg,
      disableClose: true,
      autoFocus: true,
      restoreFocus: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (dialogRef.componentInstance.status == 'endForAll') {
        // stop session
        this.exitSession = false;
        this.stopBroadcast(false);
      } else if (dialogRef.componentInstance.status == 'Leave') {
        // exit from session
        this.exitSession = true;
        this.stopBroadcast(false);
      }
    });
  }

  stopBroadcastBtnClick() {
    this.stopRinger(true);
    // console.log("i am host " + this.isIamHost());
    //for electron
    this.onClickOpenMainWindow();

    if (this.isIamHost()) {
      // if (this.channelDetails?.liveClassHosts?.length > 1) {
      //   //multiple host is present ask to exit or stop
      //   this.showExitOrStopAlert('Stop or Exit');
      // } else {
      //user is host and only one host is present so call stop broadcast
      this.exitSession = false;
      this.stopBroadcast(false);
      // }
    } else {
      //user is not host so
      // exit from session
      this.exitSession = true;
      this.stopBroadcast(false);
    }
  }

  /**
   *stop Broadcast
   */
  stopBroadcast(autoReconnectEnabled: boolean = false) {
    if (!autoReconnectEnabled) {
      this.broadcastService.autoReconnectChannel = null;
    }
    try {
      this.disconnected = true;
      this.removeAllRoomEventListeners();
      if (this.conference) {
        this.removeCommands();
        this.conference.leave();
        console.log('leave conference');
      }
      if (this.connection) {
        this.connection.disconnect();
        console.log('disconnect connection');
      }
      clearInterval(this.timer);
    } catch (error) {
      // console.log("broadcaster stopBroadcast error");
      console.error(error);
      this.disconnect();
    }
  }

  removeAllRoomEventListeners() {
    if (this.conference) {
      console.log('removeAllRoomEventListeners called');

      try {
        this.conference.off(
          JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED,
          this._onTrackMuteChanged.bind(this)
        );
        this.conference.off(
          JitsiMeetJS.events.conference.TRACK_ADDED,
          this._onRemoteTrackAdded.bind(this)
        );
        this.conference.off(
          JitsiMeetJS.events.conference.TRACK_REMOVED,
          this._onRemoteTrackRemoved.bind(this)
        );
        this.conference.off(
          JitsiMeetJS.events.conference.CONFERENCE_JOINED,
          this.onConferenceJoined.bind(this)
        );
        this.conference.off(
          JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED,
          this._onLocalStatsUpdated.bind(this)
        );
        this.conference.off(
          JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED,
          this._onRemoteStatsUpdated.bind(this)
        );
        this.conference.off(
          JitsiMeetJS.events.conference.USER_JOINED,
          this.onNewUserJoinedOnConference.bind(this)
        );
        this.conference.off(
          JitsiMeetJS.events.conference.USER_LEFT,
          this.onUserLeftFromConference.bind(this)
        );
        this.conference.off(
          JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
          this.onTrackAudioLevelChanged.bind(this)
        );
        this.conference.off(
          JitsiMeetJS.events.conference.P2P_STATUS,
          this.onP2Pstatus.bind(this)
        );
      } catch (error) {
        console.error(error);
      }
    }
  }

  /**
   * This function is called when we disconnect.
   */
  disconnect() {
    console.log('disconnect!');
    this.removeAllRoomEventListeners();
    // this.liveStreamRecordingService.stopRecording();
    this.isJoined = false;
    this.disconnected = true;
    this.startVideoCall = false;
    this.onBroadcast.emit(false);
    if (this.exitSession) {
      if (this.isIamHost()) {
        //call exit host api if user is host and session is started by this user and multiple host is present
        this.exitFromHostApi();
      } else {
        //call participant left api if user is not host or user is host and session started by other user
        this.exitFromLiveClassEntryApi();
      }
    } else {
      //call stop broadcast api
      this.onStopBroadcast();
    }
    this.kayoolMeetService.triggerSessionStop();
    if (this.iamBroadcaster) {
      this.broadcastService.onStopBroadcast();
    } else {
      this.broadcastService.onStopViewingBroadcast();
    }
    this.connection.removeEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      this.onConnectionSuccess.bind(this)
    );
    this.connection.removeEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      this.onConnectionFailed.bind(this)
    );
    this.connection.removeEventListener(
      JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
      this.disconnect.bind(this)
    );

    try {
      // if (this.presenterEffect) this.presenterEffect.stopEffect();
      // if (this.desktopVideoStream) stopStream(this.desktopVideoStream);
      if (this.cameraVideoTrack) {
        // console.log("video track found. removing..");
        stopTrack(this.cameraVideoTrack);
      }
      // if (this.desktopVideoTrack) stopTrack(this.desktopVideoTrack);
      // if (this.localPresenterVideoTrack)
      //   stopTrack(this.localPresenterVideoTrack);
      if (this.audioEffect) {
        // console.log("stoping audio effect");
        if (this.audioTrack) this.audioTrack.setEffect(undefined);
        this.audioEffect.stopEffect();
      }
      if (this.desktopAudioTrack) stopTrack(this.desktopAudioTrack);
      if (this.audioTrack) {
        // console.log("audio track found. removing..");
        stopTrack(this.audioTrack);
      }
      if (this.desktopVideoTrack) {
        stopTrack(this.desktopVideoTrack);
      }
      if (this.cameraVideoTrack) {
        stopTrack(this.cameraVideoTrack);
      }
    } catch (error) {
      console.error(error);
    }
    this.kayoolMeetService.resetAll();
  }

  async _createPresenterStreamEffect() {
    if (!this.localPresenterVideoTrack) {
      try {
        this.localPresenterVideoTrack = await this.createLocalPresenterTrack(
          this.mainVideoProps.height
        );
      } catch (error) {
        console.error('Failed to create a camera track for presenter', error);
      }
    }
    try {
      const effect = await this.createPresenterEffect(
        this.localPresenterVideoTrack.stream
      );

      return effect;
    } catch (err) {
      console.error('Failed to create the presenter effect', err);
    }
    return null;
  }

  /**
   * Creates a new instance of JitsiStreamPresenterEffect.
   *
   * @param {MediaStream} stream - The video stream which will be used for
   * creating the presenter effect.
   * @returns {Promise<JitsiStreamPresenterEffect>}
   */
  createPresenterEffect(stream: MediaStream) {
    if (
      !MediaStreamTrack.prototype.getSettings &&
      !MediaStreamTrack.prototype.getConstraints
    ) {
      return Promise.reject(new Error('StreamPresenterEffect not supported!'));
    }

    return Promise.resolve(new JitsiStreamPresenterEffect(stream));
  }

  /**
   * Creates a local video track for presenter. The constraints are computed based
   * on the height of the desktop that is being shared.
   *
   * {@code undefined} to use app's settings.
   * @param {number} desktopHeight - The height of the desktop that is being
   * shared.
   * @returns {Promise<JitsiLocalTrack>}
   */
  async createLocalPresenterTrack(desktopHeight) {
    // compute the constraints of the camera track based on the resolution
    // of the desktop screen that is being shared.
    const cameraHeights = [180, 270, 360, 540, 720];
    const proportion = 5;
    // const result = cameraHeights.find(
    //   (height) => desktopHeight / proportion < height
    // );
    const result = 120;
    const constraints = {
      video: {
        aspectRatio: 4 / 3,
        height: {
          ideal: result,
        },
      },
    };
    const [videoTrack] = await JitsiMeetJS.createLocalTracks({
      constraints,
      devices: [MediaDevice.VIDEO],
    });

    videoTrack.type = 'presenter';

    return videoTrack;
  }

  onClickStopScreenshare() {
    if (this.screenShareProcessing) return;
    this.screenShareProcessing = true;
    console.log('exit from share screen');
    if (this.audioEffect) {
      //remove system audio
      if (this.audioTrack) {
        this.audioTrack.setEffect(undefined);
      }
      this.audioEffect = null;
      if (this.desktopAudioTrack) {
        this.desktopAudioTrack.dispose();
        this.desktopAudioTrack = null;
      }
    }
    this.desktopVideoTrack.mute();
    this.screenSharing = false;
    this.screenShareProcessing = false;
    // for electron
    // if (this.electronService.isElectron && this.screenSharingRender) {
    //   this.screenSharingRender._onScreenSharingStatusChanged(false);
    // }

    // if (this.localPresenterVideoTrack) {
    //   //remove presenter track
    //   stopTrack(this.localPresenterVideoTrack);
    //   this.localPresenterVideoTrack = null;
    // }
    // if (this.presenterEffect) {
    //   try {
    //     let tracks = await JitsiMeetJS.createLocalTracks({
    //       devices: [MediaDevice.VIDEO],
    //     });
    //     this.kayoolMeetService.showCameraPermissionError(false);

    //     this.removePreviousLocalVideoTrack();
    //     this.cameraVideoTrack = tracks[0];
    //     //replace desktop track with camera track
    //     this.conference.replaceTrack(this.desktopVideoTrack, this.cameraVideoTrack);
    //     this.kayoolMeetService.remoteTracks[this.myJitsiID].push(this.cameraVideoTrack);
    //     this.kayoolMeetService.setPinParticipantStreamForRecording();
    //     stopTrack(this.desktopVideoTrack);
    //     this.desktopVideoTrack = null;
    //   } catch (error) {
    //     this.kayoolMeetService.showCameraPermissionError(true);
    //     console.error(error);

    //     /**
    //      * remove previous video track
    //      */
    //     this.removePreviousLocalVideoTrack();
    //     if (this.desktopVideoTrack) this.desktopVideoTrack.dispose();
    //   }
    // }

    // stopTrack(this.desktopVideoTrack);
    // this.desktopVideoTrack = null;
    // stopStream(this.desktopVideoStream);
    // this.desktopVideoStream = null;
    // if (this.presenterEffect) {
    //   this.presenterEffect.stopEffect();
    //   this.presenterEffect = null;
    // }
  }

  /**
   *
   */
  async onClickStartShareScreen() {
    if (this.screenShareProcessing) return;
    this.screenShareProcessing = true;
    console.log('starting to share screen');

    //share screen
    try {
      let tracks = await JitsiMeetJS.createLocalTracks({
        devices: [MediaDevice.DESKTOP],
      });
      let dTrack = null;
      console.log(tracks);

      console.log('screen captured');
      tracks.forEach((track) => {
        if (track.getType() == MediaType.VIDEO) {
          dTrack = track;
        }
        if (track.getType() == MediaType.AUDIO) {
          this.desktopAudioTrack = track;
          this.audioEffect = new AudioMixerEffect(this.desktopAudioTrack);
          if (this.audioTrack) {
            this.audioTrack.setEffect(this.audioEffect);
          }
          // else {
          // this.conference.addTrack(this.desktopAudioTrack);
          // }
        }
      });

      // this.desktopVideoStream = dVideoTrack.stream;
      // const firstVideoTrack = this.desktopVideoStream.getVideoTracks()[0];
      // this.mainVideoProps = await this.getTrackProps(firstVideoTrack, true);
      this.screenSharing = true;
      if (this.desktopVideoTrack) {
        this.conference.replaceTrack(this.desktopVideoTrack, dTrack);
      } else {
        this.conference.addTrack(dTrack);
      }
      this.desktopVideoTrack = dTrack;

      this.kayoolMeetService.setPinParticipantStreamForRecording();
      // if (!this.cameraMuted) {
      //   try {
      //     this.presenterEffect = await this._createPresenterStreamEffect();
      //   } catch (err) {
      //     console.error("Failed to set Presenter Video", err);
      //     this.cameraMuted = true;
      //   }
      //   this.desktopVideoTrack.setEffect(this.presenterEffect);
      //   console.log("presenter effect added");
      //   if (this.disconnected) {
      //     try {
      //       if (this.localPresenterVideoTrack)
      //         stopTrack(this.localPresenterVideoTrack);
      //       if (this.presenterEffect) this.presenterEffect.stopEffect();
      //     } catch (error) {
      //       console.error(error);
      //     }
      //   }
      // }

      this.desktopVideoTrack?.on(
        JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
        (track) => {
          console.log('LOCAL_TRACK_STOPPED');
          if (this.screenSharing) {
            this.onClickStopScreenshare();
          }
        }
      );

      /**
       * listener for browser stop sharing button
       */
      // this.addStreamStopListener(this.desktopVideoTrack.stream, () => {
      //   console.log("on stop screen share stream");

      //   if (this.screenSharing && !this.stopBroadcastRequestReceived) {
      //     this.onClickStopScreenshare();
      //   }
      // });
    } catch (error) {
      console.error(error);
      console.error('error capturing screen');
    }

    setTimeout(() => {
      console.log(this.kayoolMeetService.participants);
    }, 1000);

    this.screenShareProcessing = false;
    //for electron
    // if (this.electronService.isElectron && this.screenSharingRender) {
    //   this.screenSharingRender._onScreenSharingStatusChanged(
    //     this.screenSharing
    //   );
    // }
  }

  async getTrackProps(videoTrack, desktopTrack: boolean) {
    const { height, width, frameRate } =
      videoTrack.getSettings() ?? videoTrack.getConstraints();

    const isPortrait = height >= width;
    const DESKTOP_STREAM_CAP = 720;
    const highResolutionTrack =
      (isPortrait && width > DESKTOP_STREAM_CAP) ||
      (!isPortrait && height > DESKTOP_STREAM_CAP);

    // Resizing the desktop track for presenter is causing blurriness of the desktop share on chrome.
    // Disable resizing by default, enable it only when config.js setting is enabled.
    // Firefox doesn't return width and height for desktop tracks. Therefore, track needs to be resized
    // for creating the canvas for presenter.
    const resizeDesktopStream =
      this.broadcastService.isFireFox() || highResolutionTrack;

    let prop = {};

    if (resizeDesktopStream && desktopTrack) {
      let desktopResizeConstraints: any = {};

      if (height && width) {
        const advancedConstraints: any[] = [
          { aspectRatio: (width / height).toPrecision(4) },
        ];
        const constraint = isPortrait
          ? { width: DESKTOP_STREAM_CAP }
          : { height: DESKTOP_STREAM_CAP };

        advancedConstraints.push(constraint);
        desktopResizeConstraints.advanced = advancedConstraints;
      } else {
        desktopResizeConstraints = {
          width: 1280,
          height: 720,
        };
      }

      // Apply the constraints on the desktop track.
      try {
        await this.desktopVideoTrack.track.applyConstraints(
          desktopResizeConstraints
        );
        console.log('desktop constraints resized');
      } catch (err) {
        console.error(
          'Failed to apply constraints on the desktop stream for presenter mode',
          err
        );
      }
    }

    prop = {
      height: height == 0 ? 720 : height,
      width: width == 0 ? 1280 : width,
      frameRate: frameRate,
    };
    return prop;
  }

  /**
   * this method will listen stream stopped or ended events
   * @param stream
   * @param callback
   */
  addStreamStopListener(stream, callback) {
    stream.addEventListener(
      'ended',
      () => {
        callback();
        callback = () => {};
      },
      false
    );
    stream.addEventListener(
      'inactive',
      () => {
        callback();
        callback = () => {};
      },
      false
    );
    stream.getTracks().forEach((track) => {
      track.addEventListener(
        'ended',
        () => {
          callback();
          callback = () => {};
        },
        false
      );
      track.addEventListener(
        'inactive',
        () => {
          callback();
          callback = () => {};
        },
        false
      );
    });
  }

  async onClickUnmuteMyMic() {
    if (this.micProcessing) return;
    this.micProcessing = true;
    if (this.audioTrack) {
      if (this.audioTrack.isMuted()) {
        this.audioTrack.unmute();
      } else {
        this.audioTrack.mute();
      }
    } else {
      /**
       * no audio track
       * add new audio track
       */
      // await this.getLocalMediaTracks(true, false);
    }
    this.micProcessing = false;
  }

  onClickMuteMyMic() {
    if (this.audioTrack) {
      this.audioTrack.mute();
    }
  }

  async onClickUnmuteCamera() {
    if (this.cameraProcessing) return;
    if (this.cameraVideoTrack) {
      if (this.cameraVideoTrack.isMuted()) {
        this.cameraVideoTrack.unmute();
      }
    } else {
      console.log('toggleCamera2');

      /**
       * video track is not present
       * add new video track
       */
      // await this.getLocalMediaTracks(false, true);
    }
  }

  onClickMuteCamera() {
    /**
     * video track present
     */
    this.cameraVideoTrack.mute();
  }

  isMicrophoneMuted() {
    if (this.audioTrack) {
      return this.audioTrack.isMuted();
    }
    return true;
  }

  getShareScreenText() {
    return this.screenSharing ? false : true;
  }

  /**
   * unmute remote participant mic
   */
  unmuteRemoteParticipantMic(participant) {
    this.conference.sendCommandOnce(UserCommand.UNMUTE_ONE_MIC, {
      value: participant,
      attributes: {},
    });
  }

  /**
   * mute remote participant mic
   */
  muteRemoteParticipantMic(participant) {
    this.conference.sendCommandOnce(UserCommand.MUTE_ONE_MIC, {
      value: participant,
      attributes: {},
    });
  }

  /**
   * send broadcast started notification to all channel subscribers
   * @param jitsiUserID
   */
  onBroadcastStarted(jitsiUserID: string) {
    console.log('onBroadcastStarted');
    const chnlBrodReq: any = {
      broadcasterID: jitsiUserID,
    };
  }

  joinParticipantsEntryReport() {
    let userID = this.broadcastService.broadcastConfig.kollegenetUserID;
    let broadcastingChannel = this.broadcastService.getBroadcastingChannel();
  }

  /**
   * notifiy all channel subscribers that broadcast stopped
   */
  onStopBroadcast() {
    // let userID = this.broadcastService.broadcastConfig.kollegenetUserID;
    // let broadcastingChannel = this.broadcastService.getBroadcastingChannel();
  }

  /**lefting participants details for reporting */
  exitFromLiveClassEntryApi() {
    // let userID = this.broadcastService.broadcastConfig.kollegenetUserID;
    // let broadcastingChannel = this.broadcastService.getBroadcastingChannel();
  }

  exitFromHostApi() {
    // let userID = this.broadcastService.broadcastConfig.kollegenetUserID;
    // let broadcastingChannel = this.broadcastService.getBroadcastingChannel();
  }

  /**
   * set the desired resolution to send to JVB or the peer (180, 360, 720).
   * @param resolution
   */
  setSenderVideoResolution(resolution) {
    if (this.conference) {
      try {
        this.conference.setSenderVideoConstraint(resolution);
        // console.log('UPLOAD VIDEO QUALITY ' + resolution);
      } catch (error) {
        console.error(error);
      }
    }
  }

  mouseoverEvent() {
    this.changeMousePoint = true;
    if (this.controllsTimeout) {
      clearTimeout(this.controllsTimeout);
    }
    this.controllsTimeout = setTimeout(() => {
      this.changeMousePoint = false;
    }, 10000);
  }

  isBrowserSupported() {
    return JitsiMeetJS.isDesktopSharingEnabled();
  }

  getRemoteVideoStreamOfParticipants(participant): MediaStream {
    if (participant) {
      //show user camera in participant view if screen is sharing
      // if (
      //   participant == this.kayoolMeetService.presenter &&
      //   participant == this.myJitsiID &&
      //   this.screenSharing && (this.kayoolMeetService.view == SCREEN_VIEWS.MAIN_VIEW || this.kayoolMeetService.view == SCREEN_VIEWS.WHITEBOARD)
      // ) {
      //   if (this.localPresenterVideoTrack) {
      //     return this.localPresenterVideoTrack.stream;
      //   }
      //   return null;
      // }
      // !this.screenSharing
      let tracks = this.kayoolMeetService.remoteTracks[participant];
      if (tracks) {
        let videoTrackIndex = tracks.findIndex(
          (t) => t.getType() === MediaType.VIDEO && !t.isMuted()
        );
        if (videoTrackIndex > -1) {
          let track = tracks[videoTrackIndex];
          return track.stream;
        }
      }
    }
    return null;
  }

  isCameraMuted() {
    if (this.cameraVideoTrack) return this.cameraVideoTrack.isMuted();
    return true;
  }

  /**
   * select a participant for high quality video
   * @param participant
   */
  selectPresenter(jitsiUserID) {
    if (this.kayoolMeetService.presenter == jitsiUserID) return;
    if (
      this.kayoolMeetService.presenter == this.myJitsiID &&
      jitsiUserID != this.myJitsiID &&
      this.screenSharing
    ) {
      //this user was presenter and in screen shared mode
      //then switching to other user so we want to exit from screen share
      this.onClickStopScreenshare();
    }

    /**
     * select a participant to get HD video of this user from video bridge
     */
    this.kayoolMeetService.presenter = jitsiUserID;
    if (this.myJitsiID == jitsiUserID) {
      // this.componentHelper.showToast(
      //   'You are presenter now',
      //   2000,
      //   'start',
      //   'bottom'
      // );
    }
  }

  getParticipantName(participant: any) {
    // let userdID = this.userService.getUserid();
    // if (Number.parseInt(participant.kollegenetUser) == userdID) {
    //   return 'You';
    // }
    return participant.firstName + ' ' + participant.lastName;
  }

  isIamPresenter(participant: any) {
    if (
      participant.jitsiUserID == this.kayoolMeetService.presenter &&
      this.kayoolMeetService.presenter != ''
    ) {
      return true;
    }
    return false;
  }

  /**
   * returns true if broadcast in multiple mode
   */
  inMultipleMode() {
    let config = this.broadcastService.broadcastConfig;
    if (config) {
      return config.twoSideVideo;
    }
    return false;
  }

  getBroadcasterID() {
    return this.broadcastService.broadcastingChannel?.broadcastingAdminID;
  }

  getUserID() {
    return 0;
  }

  /**
   * check remote audio track muted or not
   * @param participant
   */
  isRemoteAudioTrackMuted(participant) {
    if (participant) {
      let tracks = this.kayoolMeetService.remoteTracks[participant];
      if (tracks) {
        let audioTrackIndex = tracks.findIndex(
          (t) => t.getType() === MediaType.AUDIO
        );
        if (audioTrackIndex > -1) {
          let track = tracks[audioTrackIndex];
          return track.isMuted();
        }
      }
    }
    return true;
  }

  timerCompleted($event) {
    console.log($event);
  }

  timer: any;
  settimer(minute) {
    //  let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;
      this.wait_for_host_timer = `${prefix}${Math.floor(
        seconds / 60
      )}:${textSec}`;
      if (seconds == 0) {
        clearInterval(this.timer);
      }
      if (!this.broadcastService.waitForHost) {
        clearInterval(this.timer);
      }
    }, 1000);
  }

  // For electron popup window
  getUserAvatar() {
    let avatar = 'assets/images/avatar-user.jpg';

    // if (this.electronService.isElectron && this.alwaysOnTopRender) {
    //   avatar = this.alwaysOnTopRender.getAssetsUrlAsFileUrl(avatar);
    // }
    if (this.avatarUrl) {
      return this.avatarUrl;
    }
    return avatar;
  }

  // For electron popup window
  getUserCameraStream() {
    // if (this.screenSharing && this.localPresenterVideoTrack && !this.localPresenterVideoTrack.isMuted()) {
    //   return this.localPresenterVideoTrack.stream;
    // } else {
    if (this.cameraVideoTrack && !this.cameraVideoTrack.isMuted()) {
      return this.cameraVideoTrack.stream;
    }
    // }
    return null;
  }

  // For electron popup window
  onClickOpenMainWindow() {
    // if (this.electronService.isElectron) {
    //   this.alwaysOnTopRender._switchToMainWindow();
    // }
  }

  // For electron popup window
  onToggleUserPreview() {
    this.showAlwaysOnTopUserView = !this.showAlwaysOnTopUserView;
    this.maxNoOfParticipantsPopupView =
      this.getNoofParticipantsCanBeVisibleInPopup();
    this.determinePopupViewHeight();
  }

  // For electron popup window
  getNoofParticipantsCanBeVisibleInPopup() {
    let screenHeight = this.alwaysOnTopRender.getScreenHeight();
    // let viewHeight = window.innerHeight;
    let youPanel = 32;
    let userCameraPreview = 132;
    let participantsPanel = 32;
    if (!this.showAlwaysOnTopUserView) userCameraPreview = 0;
    let remHeight =
      screenHeight - (youPanel + userCameraPreview + participantsPanel);
    let oneUserPreview = 132;
    let noOfBoxes = remHeight / oneUserPreview;
    return Math.trunc(noOfBoxes);
  }

  // For electron popup window
  onToggleParticipantView() {
    this.showAlwaysOnTopParticipantView = !this.showAlwaysOnTopParticipantView;
    this.maxNoOfParticipantsPopupView =
      this.getNoofParticipantsCanBeVisibleInPopup();
    this.determinePopupViewHeight();
  }

  // For electron popup window
  determinePopupViewHeight() {
    // console.log("determine screen height");
    if (this.alwaysOnTopRender) {
      this.updateScreenHeight();
    }
  }

  // For electron popup window
  updateScreenHeight() {
    // let screenHeight = this.alwaysOnTopRender.getScreenHeight();
    let screenHeight = window.innerHeight;

    let youPanel = 32;
    let userCameraPreview = 132;
    let participantsPanel = 32;
    if (!this.showAlwaysOnTopUserView) userCameraPreview = 0;
    let participantsViewMaxHeight =
      screenHeight - (youPanel + userCameraPreview + participantsPanel);
    if (!this.showAlwaysOnTopParticipantView) participantsViewMaxHeight = 0;
    let oneParticipantViewHeight = 132;
    let noOfParticipantsToShow =
      this.getParticipantsWithoutCurrentUser().length;
    if (noOfParticipantsToShow == 0) participantsPanel = 0;
    let participantViewHeight =
      oneParticipantViewHeight * noOfParticipantsToShow;
    console.log(screenHeight, participantViewHeight, participantsViewMaxHeight);

    if (participantViewHeight > participantsViewMaxHeight)
      participantViewHeight = participantsViewMaxHeight;
    let height =
      youPanel + userCameraPreview + participantsPanel + participantViewHeight;
    this.popupViewParticipantsHeight = participantViewHeight;
    try {
      this.alwaysOnTopRender.setScreenHeight(height);
    } catch (error) {
      console.error(error);
    }
  }

  // For electron popup window
  getParticipantsWithoutCurrentUser() {
    let participants = this.kayoolMeetService.participants.filter(
      (p) => p.jitsiUserID != this.myJitsiID
    );
    return participants;
  }

  // For electron popup window
  getParticipantAvatar(url) {
    let avatar = 'assets/images/avatar-user.jpg';

    // if (this.electronService.isElectron && this.alwaysOnTopRender) {
    //   avatar = this.alwaysOnTopRender.getAssetsUrlAsFileUrl(avatar);
    // }
    if (url) {
      return url;
    }
    return avatar;
  }

  // For electron popup window
  getParticipantsTitle() {
    let title = 'Participants';
    let participants = this.getParticipantsWithoutCurrentUser();
    if (participants.length > 0) {
      title += ' (' + participants.length + ')';
    }
    return title;
  }

  // For electron popup window
  onClickPopupParticipantMic(participantID) {
    if (this.iamBroadcaster) {
      if (this.isRemoteAudioTrackMuted(participantID))
        this.unmuteRemoteParticipantMic(participantID);
      else this.muteRemoteParticipantMic(participantID);
    }
  }

  isliveClassHost(participant: IParticipant) {
    // let liveClassHosts = this.channelDetails?.liveClassHosts;
    // for (let i = 0; i < liveClassHosts?.length; i++) {
    //   if (Number(participant?.kollegenetUser) == liveClassHosts[i]?.userID) {
    //     return true;
    //   }
    // }
    return false;
  }

  isIamHost() {
    // let liveClassHosts = this.channelDetails?.liveClassHosts;
    return isIamHost(this.getUserID(), []);
  }

  makeHost(participant: IParticipant) {
    if (this.hostChangeLoader[participant.kollegenetUser]) {
      return;
    }
  }

  removeLiveClassHost(participant: IParticipant) {
    if (this.hostChangeLoader[participant.kollegenetUser]) {
      return;
    }
  }

  removeCommands() {
    // console.log('removing all user commands');

    this.conference.removeCommand(UserCommand.USER_INFO);
    this.conference.removeCommand(UserCommand.PRESENTER);
    this.conference.removeCommand(UserCommand.SCREEN_MODE);
    this.conference.removeCommand(UserCommand.WHITE_BORAD_TOOLBAR);
  }

  onClickWhiteBoard() {
    if (this.kayoolMeetService.view == SCREEN_VIEWS.WHITEBOARD) {
      this.kayoolMeetService.view = this.previous_view;
      this.conference.sendCommand(UserCommand.SCREEN_MODE, {
        value: ScreenModes.VIDEO,
        attributes: {
          from: this.myJitsiID,
        },
      });
    } else {
      //if screen is sharing exit
      if (this.screenSharing) {
        this.onClickStopScreenshare();
      }
      //if broadcaster is not presenter, switch broadcaster as presenter
      if (
        this.kayoolMeetService.presenter != '' &&
        this.kayoolMeetService.presenter != this.myJitsiID
      ) {
        this.kayoolMeetService.sendPresenter(this.myJitsiID);
      }
      this.previous_view = this.kayoolMeetService.view;
      this.kayoolMeetService.view = SCREEN_VIEWS.WHITEBOARD;
      this.broadcastService.broadcastFullScreen(true);
      this.conference.sendCommand(UserCommand.SCREEN_MODE, {
        value: ScreenModes.WHITE_BOARD,
        attributes: {
          from: this.myJitsiID,
        },
      });
    }
  }

  /**
   * send command to all participants to unmute their mic
   */
  unMuteAllParticipants() {
    this.conference.sendCommandOnce(UserCommand.UNMUTE_ALL_MIC, {
      value: UserCommand.UNMUTE_ALL_MIC,
      attributes: {},
    });
  }

  /**
   * send command to mute all participants
   */
  muteAllParticipants() {
    this.conference.sendCommandOnce(UserCommand.MUTE_ALL_MIC, {
      value: UserCommand.MUTE_ALL_MIC,
      attributes: {},
    });
  }

  onClickSettings() {
    const dialogRef = this.dialog.open(
      KmeetDeviceSettingsComponent
      // {
      //   panelClass: "custom-dialog-container",
      //   data: {
      //     clsID: this.channelDetails.id
      //   },
      // }
    );
  }

  onToggleView() {
    if (this.kayoolMeetService.view != SCREEN_VIEWS.GRID_PARTICIPANT_VIEW) {
      this.previous_view = this.kayoolMeetService.view;
      this.kayoolMeetService.view = SCREEN_VIEWS.GRID_PARTICIPANT_VIEW;
    } else {
      this.kayoolMeetService.view = this.previous_view;
    }
  }

  onClickNavigationButton(view: string) {
    this.broadcastService.triggerLiveclassNavigation(view);
    if (view == this.navigatedView) {
      this.navigatedView = '';
    } else {
      this.navigatedView = view;
    }

    switch (view) {
      // case "resources":

      //     break;
      case 'messages':
        // if (this.channelDetails) {
        //   this.channelDetails.unseenMessages = 0;
        // }
        break;
      case 'assessment':
        this.notAttendingQuizCount = 0;
        break;

      default:
        break;
    }
  }

  //event - LOCAL_STATS_UPDATED
  _onLocalStatsUpdated(stats: IStats) {
    this.kayoolMeetService.triggerUserStats({
      jitsiUserID: this.myJitsiID,
      stats: stats,
    });
  }

  // event - REMOTE_STATS_UPDATED
  _onRemoteStatsUpdated(jitsiUserID: string, stats: IStats) {
    this.kayoolMeetService.triggerUserStats({
      jitsiUserID: jitsiUserID,
      stats: stats,
    });
  }

  _onRemoteTrackRemoved(track) {
    this.kayoolMeetService.onRemoteTrackRemoved(track);
  }

  _onRemoteTrackAdded(track) {
    this.kayoolMeetService.onRemoteTrackAdded(track);
  }

  getPresentingParticipant() {
    return this.kayoolMeetService.presenter;
  }

  toogleFooterButtonsVisibility() {
    this.showFooterControls = !this.showFooterControls;
  }

  getView() {
    return this.kayoolMeetService.view;
  }

  getRemoteAudioLevel(jitsiUserID: string) {
    return this.kayoolMeetService.remoteAudioLevels[jitsiUserID];
  }

  isFullscreen() {
    return this.broadcastService.fullScreen;
  }

  isWaitForHost() {
    return this.broadcastService.waitForHost;
  }

  onClickFullScreen() {
    if (!this.broadcastService.fullScreen) {
      this.broadcastService.broadcastFullScreen(true);
    } else {
      this.broadcastService.broadcastFullScreen(false);
    }
  }

  startRing() {
    this.ringing = true;
    this.ringer.nativeElement.currentTime = 0;
    this.ringer.nativeElement.play();

    this.checkCallAccepted();
  }

  checkCallAccepted() {
    if (!this.ringing) return;
    this.websocketService
      .getStatusOfACall(this.roomName)
      .then((res: CallStatus) => {
        if (!res.answered) {
          setTimeout(() => {
            this.checkCallAccepted();
          }, 1000);
        } else {
          this.stopRinger(true);
          this.connectingWithManager = true;
        }
      })
      .catch((err) => {
        setTimeout(() => {
          this.checkCallAccepted();
        }, 1000);
        console.error(err);
      });
  }

  stopRinger(force: boolean = false) {
    if (force) {
      this.ringing = false;
      this.ringer.nativeElement.pause();
    } else {
      if (this.kayoolMeetService.participants.length > 1) {
        this.ringing = false;
        this.ringer.nativeElement.pause();
        this.connectingWithManager = false;
      }
    }
  }

  onRingerEnded(event) {
    console.log('ringer stoped');
    this.stopBroadcastBtnClick();

    this.dialog.open(AlertDialogComponent, {
      data: {
        title: 'Call Disconnected',
        message:
          'All our customer care executives are very busy, Please call again after some time.',
      },
    });
  }
}
