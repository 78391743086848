import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MarketPlaceComponent } from './pages/market-place/market-place.component';
import { AboutComponent } from './pages/about/about.component';
import { MenuAndPricingComponent } from './pages/menu-and-pricing/menu-and-pricing.component';
import { TeamComponent } from './pages/team/team.component';
import { TestimonialComponent } from './pages/testimonial/testimonial.component';
import { OurServiceComponent } from './pages/our-service/our-service.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ExploreProductsComponent } from './pages/menu-and-pricing/explore-products/explore-products.component';
import { ProductItemComponent } from './pages/menu-and-pricing/explore-products/product-item/product-item.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule } from '@ionic/angular';
import { ChannelVideoBroadcastModule } from './modules/kayool-meet/channel-video-broadcast.module';
import { WebsiteHeaderComponent } from './pages/website-header/website-header.component';
import { HttpClientModule } from '@angular/common/http';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { WebsiteFooterComponent } from './pages/website-footer/website-footer.component';
import { OfferSectionComponent } from './pages/offer-section/offer-section.component';
import { VideoCallFormComponent } from './pages/video-call-form/video-call-form.component';
import { FormsModule } from '@angular/forms';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { AlertDialogComponent } from './pages/alert-dialog/alert-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    MarketPlaceComponent,
    AboutComponent,
    MenuAndPricingComponent,
    TeamComponent,
    TestimonialComponent,
    OurServiceComponent,
    ContactComponent,
    ExploreProductsComponent,
    ProductItemComponent,
    WebsiteHeaderComponent,
    WebsiteFooterComponent,
    OfferSectionComponent,
    VideoCallFormComponent,
    AdminPageComponent,
    AlertDialogComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatIconModule,
    IonicModule.forRoot(),
    ChannelVideoBroadcastModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
