<div class="dialog-container p-2">
  <div
    style="
      display: flex;
      justify-content: space-between;
      color: red;
      font-weight: 500;
      padding-bottom: 5px;
    "
    class="border-bottom"
  >
    <div style="display: flex">
      <span style="margin-right: 3px">
        <svg style="width: 16px; height: 20px" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M12,2L1,21H23M12,6L19.53,19H4.47M11,10V14H13V10M11,16V18H13V16"
          />
        </svg>
      </span>
      <span style="font-size: 16px">Stop session or Exit</span>
    </div>
    <i
      style="cursor: pointer; color: black"
      class="material-icons"
      (click)="closeDialog()"
      >close</i
    >
  </div>
  <p style="font-size: small; margin: 10px 0px; text-align: center">
    If you want to stop the session, please click on the
    <b>Stop For All</b> button otherwise you can leave the session without
    disturbing others by clicking on the <b>Leave</b> button.
  </p>
  <div class="d-flex justify-center">
    <button
      mat-button
      (click)="endForAll()"
      style="
        color: red;
        background-color: white;
        border: 1px solid red;
        margin: 0px 5px;
      "
    >
      Stop For All
    </button>
    <button
      mat-button
      (click)="Leave()"
      style="color: white; background-color: red; margin: 0px 5px"
    >
      Leave
    </button>
  </div>
</div>
