import { Component, Input, OnInit } from '@angular/core';
import { IJitsiConference, IParticipant } from '../../../model/jitsi';
import { KayoolMeetService } from '../../../service/kayool-meet.service';

@Component({
  selector: 'app-km-self-preview',
  templateUrl: './km-self-preview.component.html',
  styleUrls: ['./km-self-preview.component.css'],
})
export class KmSelfPreviewComponent implements OnInit {
  @Input() conference: IJitsiConference = null;
  participant: IParticipant = null;

  constructor(private kayoolMeetService: KayoolMeetService) {}

  ngOnInit(): void {
    this.participant = this.getMyUser();
  }

  getMyUser(): IParticipant {
    let myID = this.kayoolMeetService.myJitsiID;
    let index = this.kayoolMeetService.participants.findIndex(
      (p) => p.jitsiUserID == myID
    );
    if (index > -1) {
      return this.kayoolMeetService.participants[index];
    }
    return null;
  }

  getParticipantsCount() {
    return this.kayoolMeetService.participants.length;
  }
}
