import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-explore-products',
  templateUrl: './explore-products.component.html',
  styleUrls: ['./explore-products.component.css']
})
export class ExploreProductsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
